import React, { FC, memo } from 'react';
import styles from './InformationPage.module.scss';
import Layout from '../../components/Layout/Layout';
import UserInformationUpdateForm from '../../components/UserInformationUpdateForm/UserInformationUpdateForm';

interface Props {

}

const InformationPage: FC<Props> = memo(() => {
  return (
    <Layout className={styles.informationPage}>
      <UserInformationUpdateForm />
    </Layout>
  );
});

export default InformationPage;
