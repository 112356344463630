import React, { FC, memo } from 'react';
import styles from './EmptySceneList.module.scss';
import Typography from '../../component-system/Typography/Typography';

interface Props {}

const EmptySceneList: FC<Props> = memo(() => {
  return (
    <div className={styles.emptySceneList}>
      <Typography component="h3">😂 아무것도 없습니다...</Typography>
    </div>
  );
});

export default EmptySceneList;
