import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import styles from './UserInformationUpdateForm.module.scss';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { WHOAMI } from '../../cores/queries';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import TextField from '../../component-system/TextField/TextField';
import Switch from '../../component-system/Switch/Switch';
import FileInput from '../../component-system/FileInput/FileInput';
import Button from '../../component-system/Button/Button';
import { UPDATE_USER_INFORMATION } from '../../cores/mutations';
import { useAsset } from '../../hooks/useAsset';
import { get } from 'lodash';

interface Props {}

interface UserInformationUpdateFormValues {
  nickname: string;
  profile: File | null;
  isAvailableCollection: boolean;
}

const UserInformationUpdateForm: FC<Props> = memo(() => {
  const { data } = useQuery(WHOAMI);
  const [updateUserInformation] = useMutation(UPDATE_USER_INFORMATION);

  const onSubmit = useCallback((values: UserInformationUpdateFormValues) => {
    const data: Partial<UserInformationUpdateFormValues> = {};

    if (values.nickname) {
      data.nickname = values.nickname;
    }

    if (values.profile) {
      data.profile = values.profile;
    }

    data.isAvailableCollection = values.isAvailableCollection;

    updateUserInformation({
      variables: {
        data
      }
    });
  }, []);

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    onSubmit,
    initialValues: {
      nickname: '',
      profile: null,
      isAvailableCollection: false,
    },
  });

  useEffect(() => {
    if (!data || !data.whoami) {
      return;
    }

    setFieldValue('nickname', data.whoami.nickname || '');
    setFieldValue(
      'isAvailableCollection',
      data.whoami.isAvailableCollection
    );
  }, [data]);

  const profileSrc = useAsset('profiles', get(data, ['whoami', 'profile']));

  return (
    <div className={styles.userInformationUpdateForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <FormColumn label="콜렉션 페이지 주소" additional="닉네임">
          <TextField
            name="nickname"
            value={values.nickname}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn label="콜렉션 페이지 사용여부">
          <Switch
            value={values.isAvailableCollection}
            onChange={(value) => {
              setFieldValue('isAvailableCollection', value);
            }}
          />
        </FormColumn>
        <FormColumn label="콜렉션 로고 이미지" additional="세로 70px 배수 기준">
          {profileSrc && (
            <img className={styles.profile} src={profileSrc} />
          )}
          <FileInput
            onChange={(file) => {
              setFieldValue('profile', file);
            }}
          />
        </FormColumn>
        <div className={styles.actions}>
          <Button
            type="submit"
            variant="rounded"
            size="large"
          >
            저장하기
          </Button>
        </div>
      </form>
    </div>
  );
});

export default UserInformationUpdateForm;
