import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import styles from './Dropzone.module.scss';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import DropzoneItem from '../DropzoneItem/DropzoneItem';
import produce from 'immer';
import { MdCloudUpload } from 'react-icons/md';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import path from 'path';

interface Props {
  onDropGltfFile?: (file: File) => void;
  onChange: (files: File[]) => void;
}

export interface FileInfo {
  id: string;
  name: string;
  size: number;
  dataUrl?: string;
  status: 'pending' | 'loaded';
}

const Dropzone: FC<Props> = memo(({ onChange, onDropGltfFile }) => {
  const ref = useRef<File[]>([]);
  const [fileInfoList, setFileInfoList] = useState<FileInfo[]>([]);

  const onDrop = useCallback(
    (files: File[]) => {
      ref.current.push(...files);

      const fileInfoList: FileInfo[] = files.map((file) => {
        const id = nanoid();
        const isReadable = ['image/jpeg', 'image/png'].indexOf(file.type);

        if (path.extname(file.name) === '.gltf' && onDropGltfFile) {
          onDropGltfFile(file);
        }

        if (isReadable !== -1) {
          const reader = new FileReader();
          reader.onload = () => {
            setFileInfoList((prevFileInfoList: FileInfo[]) => {
              const index = prevFileInfoList.findIndex(
                (prevFileInfo) => prevFileInfo.id === id
              );
              if (index === -1) {
                return prevFileInfoList;
              }

              return produce(prevFileInfoList, (draft) => {
                draft[index].dataUrl = reader.result as string;
                draft[index].status = 'loaded';
              });
            });
          };
          reader.readAsDataURL(file);
        }

        return {
          id,
          name: file.name,
          size: file.size,
          status: isReadable ? 'loaded' : 'pending',
        };
      });

      setFileInfoList((prevFileInfoList: FileInfo[]) => {
        return [...prevFileInfoList, ...fileInfoList];
      });

      onChange(ref.current);
    },
    [onChange, onDropGltfFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 20,
    accept: ['.png', '.jpg', '.jpeg', '.gltf', '.bin'],
    onDrop,
    noClick: true,
  });

  const renderedDropzoneItems = useMemo(() => {
    return fileInfoList.map((fileInfo) => {
      return <DropzoneItem key={fileInfo.id} fileInfo={fileInfo} />;
    });
  }, [fileInfoList]);

  const visibleGuide = renderedDropzoneItems.length === 0;

  return (
    <div
      className={classNames(styles.dropzone, {
        [styles.isActivated]: isDragActive,
      })}
    >
      <div className={styles.area} {...getRootProps()}>
        <input {...getInputProps()} />
        {visibleGuide && (
          <div className={styles.guide}>
            <MdCloudUpload />
            <Typography component="p">
              여기로 파일을 드래그 해보세요 😎
            </Typography>
          </div>
        )}
        {renderedDropzoneItems}
      </div>
    </div>
  );
});

export default Dropzone;
