import React, { FC, memo } from 'react';
import Layout from '../../components/Layout/Layout';
import Typography from '../../component-system/Typography/Typography';

interface Props {}

const HomePage: FC<Props> = memo(() => {
  return (
    <Layout>
      <Typography component="h1">Home</Typography>
    </Layout>
  );
});

export default HomePage;
