import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './SceneListPage.module.scss';
import Button from '../../component-system/Button/Button';
import { MdCloudUpload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SCENES } from '../../cores/queries';
import { PaginatedScene } from '../../cores/schema';
import SceneListItem from '../../components/SceneListItem/SceneListItem';
import { useLoadMore } from '../../hooks/useLoadMore';
import produce from 'immer';
import { uniqBy } from 'lodash';
import SceneListSearchForm from '../../components/SceneListSearchForm/SceneListSearchForm';
import { useURLSearchParams } from '../../hooks/useURLSearchParams';
import classNames from 'classnames';
import Typography from '../../component-system/Typography/Typography';
import EmptySceneList from '../../components/EmptySceneList/EmptySceneList';
import BulkSceneEditorForm from '../../components/BulkSceneEditorForm/BulkSceneEditorForm';
import Checkbox from '../../component-system/Checkbox/Checkbox';

interface Props {}

const SceneListPage: FC<Props> = memo(() => {
  const query = useURLSearchParams();
  const { data, refetch, fetchMore, loading } = useQuery<{
    getScenes: PaginatedScene;
  }>(GET_SCENES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'merge',
    variables: {
      first: 20,
      search: query.get('search'),
      category: query.get('category'),
    },
  });

  const [selectedSceneHashIds, setSelectedSceneHashIds] = useState<string[]>(
    []
  );

  const isEnableBulkEditorForm = useMemo(
    () => selectedSceneHashIds.length > 0,
    [selectedSceneHashIds]
  );

  useEffect(() => {
    setSelectedSceneHashIds([]);
  }, [query]);

  useLoadMore(() => {
    if (
      !data ||
      !data.getScenes.pageInfo ||
      !data.getScenes.pageInfo.hasNextPage ||
      loading
    ) {
      return;
    }

    fetchMore({
      variables: {
        first: 20,
        after: data.getScenes.pageInfo.endCursor,
        search: query.get('search'),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return produce(previousResult, (draft) => {
          if (
            !previousResult ||
            !previousResult.getScenes ||
            !previousResult.getScenes.edges ||
            !fetchMoreResult ||
            !fetchMoreResult.getScenes ||
            !fetchMoreResult.getScenes.edges
          ) {
            return;
          }

          draft.getScenes.pageInfo = fetchMoreResult.getScenes.pageInfo;
          draft.getScenes.edges = uniqBy(
            [
              ...previousResult.getScenes.edges,
              ...fetchMoreResult.getScenes.edges,
            ],
            (o) => o.node.hashId
          );
        });
      },
    });
  });

  const renderedSceneListItems = useMemo(() => {
    if (!data || !data.getScenes.edges) {
      return [];
    }

    return data.getScenes.edges.map((edge) => {
      const index = selectedSceneHashIds.indexOf(edge.node.hashId);
      const isChecked = index > -1;

      return (
        <SceneListItem
          key={edge.node.hashId}
          data={edge.node}
          isChecked={isChecked}
          onChange={() => {
            setSelectedSceneHashIds((prevState) => {
              return produce(prevState, (draft) => {
                if (isChecked) {
                  draft.splice(index, 1);
                } else {
                  draft.push(edge.node.hashId);
                }
              });
            });
          }}
        />
      );
    });
  }, [data, selectedSceneHashIds, setSelectedSceneHashIds]);

  const isSelectedAll = useMemo(() => {
    if (!data || !data.getScenes.edges) {
      return false;
    }

    let isSelectedAll = true;

    for (let i = 0; i < data.getScenes.edges.length; i++) {
      if (
        selectedSceneHashIds.indexOf(data.getScenes.edges[i].node.hashId) === -1
      ) {
        isSelectedAll = false;
        break;
      }
    }

    return isSelectedAll;
  }, [data, selectedSceneHashIds]);

  return (
    <div className={classNames(
      styles.sceneListPage, {
        [styles.isEnableBulkEditorForm]: isEnableBulkEditorForm
      }
    )}>
      <SceneListSearchForm />
      <div className={styles.actions}>
        <Link to="/scenes/new">
          <Button className={styles.uploadButton}>
            <MdCloudUpload />
          </Button>
        </Link>
      </div>
      <div className={styles.sceneListHeader}>
        <div className={classNames(styles.column, styles.checkbox)}>
          <Checkbox
            value={isSelectedAll}
            onChange={(value) => {
              if (value) {
                if (!data || !data.getScenes.edges) {
                  return;
                }

                setSelectedSceneHashIds(
                  data.getScenes.edges.map((edge) => edge.node.hashId)
                );
              } else {
                setSelectedSceneHashIds([]);
              }
            }}
          />
        </div>
        <div className={classNames(styles.column, styles.descriptor)}>
          <Typography component="p" variant="info">
            제품명
          </Typography>
        </div>
        <div className={classNames(styles.column, styles.createdAt)}>
          <Typography component="p" variant="info">
            업로드 날짜
          </Typography>
        </div>
        <div className={classNames(styles.column, styles.category)}>
          <Typography component="p" variant="info">
            카테고리
          </Typography>
        </div>
        <div className={classNames(styles.column, styles.isPublished)}>
          <Typography component="p" variant="info">
            게시여부
          </Typography>
        </div>
      </div>
      <div className={styles.sceneListItemsWrap}>
        <div className={styles.sceneListItems}>
          {renderedSceneListItems.length <= 0
            ? !loading && <EmptySceneList />
            : renderedSceneListItems}
        </div>
      </div>
      {isEnableBulkEditorForm && (
        <BulkSceneEditorForm
          hashIds={selectedSceneHashIds}
          onClose={() => {
            setSelectedSceneHashIds([]);
            refetch();
          }}
        />
      )}
    </div>
  );
});

export default SceneListPage;
