import React, { FC, memo } from 'react';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';
import { MdCheck } from 'react-icons/md';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox: FC<Props> = memo(({ value, onChange }) => {
  return (
    <div className={styles.checkbox}>
      <button
        className={classNames(styles.shape, {
          [styles.isChecked]: value,
        })}
        onClick={() => {
          onChange(!value);
        }}
      >
        <MdCheck />
      </button>
    </div>
  );
});

export default Checkbox;
