export const MAX_BYTE = 1024;
export const MAX_KILOBYTE = 1024 * 1024;
export const MAX_MEGABYTE = 1024 * 1024 * 1024;
export const MAX_GIGABYTE = 1024 * 1024 * 1024 * 1024;

export const RADIAN_TO_DEGREE = 57.2958;
export const DEGREE_TO_RADIAN = 0.0174533;

export const MEDIA_HOST: string = process.env.REACT_APP_MEDIA_HOST as string;
export const VIEWER_HOST: string = process.env.REACT_APP_VIEWER_HOST as string;
