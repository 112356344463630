import React, { FC, memo } from 'react';
import styles from './DropdownGroup.module.scss';
import Typography from '../Typography/Typography';

interface Props {
  name?: string;
}

const DropdownGroup: FC<Props> = memo(({ name, children }) => {
  return (
    <div className={styles.dropdownGroup}>
      {name && (
        <div className={styles.name}>
          <Typography component="p" variant="info">
            {name}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
});

export default DropdownGroup;
