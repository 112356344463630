import React, { FC, FormEvent, memo } from 'react';
import styles from './DashBoardSearchForm.module.scss';
import Typography from '../../component-system/Typography/Typography';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import Dropdown from '../../component-system/Dropdown/Dropdown';

interface Props {
  values: { frequency: string };
  onChange: (value: string) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

const DashBoardSearchForm: FC<Props> = memo(
  ({ values, onChange, handleSubmit }) => {
    const group = [
      { options: [{ name: '30일', value: 'ONE_MONTH' }] },
      { options: [{ name: '90일', value: 'THREE_MONTH' }] },
      { options: [{ name: '180일', value: 'SIX_MONTH' }] },
    ];

    return (
      <div className={styles.dashBoardSearchForm}>
        <Typography component="h2" className={styles.header}>
          대시보드
        </Typography>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormColumn label="기간">
            <Dropdown
              value={values.frequency}
              onChange={onChange}
              placeholder="기간을 선택해주세요."
              groups={group}
            />
          </FormColumn>
        </form>
      </div>
    );
  }
);

export default DashBoardSearchForm;
