import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Category.module.scss';
import { getCategory } from '../../atoms/selectors/getCategory';
import { useKeepRecoilValue } from '../../hooks/useKeepRecoilValue';
import CategoriesForm from '../CategoriesForm/CategoriesForm';
import { useMutation } from '@apollo/client';
import { BULK_UPDATE_CATEGORIES } from '../../cores/mutations';
import Button from '../../component-system/Button/Button';
import { useRecoilState } from 'recoil';
import { triggerState } from '../../atoms/selectors/trigger';
import Spinner from '../../component-system/Spinner/Spinner';
import CategoryUpsertModal from '../CategoryUpsertModal/CategoryUpsertModal';

interface Props {}

const Category: FC<Props> = memo(() => {
  const [categories, , isLoading] = useKeepRecoilValue(getCategory({}), []);
  const [isCreateCategoryModal, setCreateCategoryModal] = useState(false);
  const [, updateTriggerState] = useRecoilState(triggerState('getCategory'));
  const [orders, setOrders] = useState<{ hashId: string; order: number }[]>([]);
  const [bulkUpdateCategories] = useMutation(BULK_UPDATE_CATEGORIES, {
    errorPolicy: 'all',
  });

  return (
    <div className={styles.category}>
      {isLoading ? (
        <Spinner />
      ) : (
        <CategoriesForm
          parent
          categories={categories}
          onChange={(items) => {
            const orders = [];

            for (let i = 0; i < items.length; i++) {
              orders.push({
                hashId: items[i].hashId,
                order: i,
              });

              if (items[i].children.length > 0) {
                for (let j = 0; j < items[i].children.length; j++) {
                  orders.push({
                    hashId: items[i].children[j].hashId,
                    order: j,
                  });
                }
              }
            }

            setOrders(orders);
          }}
        />
      )}
      <div className={styles.actions}>
        {orders.length > 0 ? (
          <Button
            variant="rounded"
            onClick={() => {
              bulkUpdateCategories({
                variables: { data: { categories: orders } },
              })
                .then(() => {})
                .finally(() => {
                  setOrders([]);
                  updateTriggerState(Date.now());
                });
            }}
          >
            순서 저장하기
          </Button>
        ) : (
          <Button variant="rounded" onClick={() => {
            setCreateCategoryModal(true);
          }}>
            상위 카테고리 추가하기
          </Button>
        )}
      </div>
      <CategoryUpsertModal
        visible={isCreateCategoryModal}
        order={categories.length}
        onClose={() => {
          setCreateCategoryModal(false);
        }}
      />
    </div>
  );
});

export default Category;
