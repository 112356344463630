import React, { FC, memo } from 'react';
import styles from './Layout.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const Layout: FC<Props> = memo(({ children, className }) => {
  return <div className={classNames(styles.layout, className)}>{children}</div>;
});

export default Layout;
