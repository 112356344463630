import React, { FC, memo, useCallback } from 'react';
import styles from './DashBoardPage.module.scss';
import DashBoardSearchForm from '../../components/DashBoardSearchForm/DashBoardSearchForm';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD } from '../../cores/queries';
import DashBoard from '../../components/DashBoard/DashBoard';
import Spinner from '../../component-system/Spinner/Spinner';

interface Props {}

const DashBoardPage: FC<Props> = memo(() => {
  const onSubmit = useCallback((values) => {
    console.log('요청 보냄');
  }, []);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      frequency: 'ONE_MONTH',
    },
    onSubmit,
  });

  const { data, loading } = useQuery(GET_DASHBOARD, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      data: { frequency: values.frequency },
    },
  });

  const onChangeDropBox = useCallback((value) => {
    setFieldValue('frequency', value);
    handleSubmit();
  }, []);

  return (
    <div className={styles.dashBoardSearchForm}>
      <DashBoardSearchForm
        values={values}
        onChange={onChangeDropBox}
        handleSubmit={handleSubmit}
      />
      {loading && <Spinner />}
      <DashBoard frequency={values.frequency} data={data} />
    </div>
  );
});

export default DashBoardPage;
