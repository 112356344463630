import { useRef } from 'react';
import FastVector from 'fast-vector';

const mousePosition = new FastVector(0, 0);

window.addEventListener('mousemove', (e) => {
  mousePosition.x = e.clientX;
  mousePosition.y = e.clientY;
});

export function useMutableMouse() {
  return useRef<FastVector>(mousePosition);
}
