import React, {FC, memo} from "react"
import classNames from "classnames";

interface Props {
  x: number
  y: number
  width: number
  height: number
  className?: string
  onMouseOver: (e: React.MouseEvent) => void
  onMouseOut: (e: React.MouseEvent) => void
}

const Rect: FC<Props> = memo(({x, y, width, height, className, ...props}) => {
  return (
    <rect
      className={classNames(className)}
      x={x}
      y={y}
      width={width}
      height={height}
      {...props}
    />
  )
})

export default Rect