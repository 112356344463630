import React, { FC, memo } from 'react';
import styles from './AsideItem.module.scss';
import { useLocationMatcher } from '../../hooks/useLocationMatcher';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Ink from 'react-ink';

interface Props {
  path: string;
}

const AsideItem: FC<Props> = memo(({ path, children }) => {
  const isActivated = useLocationMatcher(path);

  return (
    <div
      className={classNames(styles.asideItem, {
        [styles.isActivated]: isActivated,
      })}
    >
      <Link className={styles.anchor} to={path}>
        {children}
        <Ink />
      </Link>
    </div>
  );
});

export default AsideItem;
