import React, { FC, memo, useCallback, useState } from 'react';
import styles from './BulkSceneEditorForm.module.scss';
import { Portal } from 'react-portal';
import { useFormik } from 'formik';
import TextField from '../../component-system/TextField/TextField';
import TextareaField from '../../component-system/TextareaField/TextareaField';
import Dropdown from '../../component-system/Dropdown/Dropdown';
import { useCategoryGroups } from '../../hooks/useCategoryGroups';
import Switch from '../../component-system/Switch/Switch';
import PopupTemplatePreview from '../PopupTemplatePreview/PopupTemplatePreview';
import Editor from '@monaco-editor/react';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import VectorSlider from '../../component-system/VectorSlider/VectorSlider';
import Slider from '../../component-system/Slider/Slider';
import Button from '../../component-system/Button/Button';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation } from '@apollo/client';
import { BULK_DELETE_SCENE, BULK_UPDATE_SCENE } from '../../cores/mutations';
import classNames from 'classnames';
import Typography from '../../component-system/Typography/Typography';
import Modal from '../../component-system/Modal/Modal';

interface Props {
  hashIds: string[];
  onClose: () => void;
}

const BulkSceneEditorForm: FC<Props> = memo(({ hashIds, onClose }) => {
  const [bulkUpdateScene] = useMutation(BULK_UPDATE_SCENE, {
    errorPolicy: 'all',
  });

  const [bulkDeleteScene] = useMutation(BULK_DELETE_SCENE, {
    errorPolicy: 'all',
  });

  const [isVisibleConfirmDeletePopup, setVisibleConfirmDeletePopup] = useState(false);

  const onSubmit = useCallback(
    (values) => {
      const data: any = {};

      for (const key in values) {
        if (values[key] === undefined || values[key] === '') {
          continue;
        }

        data[key] = values[key];
      }

      bulkUpdateScene({
        variables: {
          data: {
            hashIds,
            data,
          },
        },
      })
        .then(() => {})
        .finally(() => {
          onClose();
        });
    },
    [hashIds, bulkUpdateScene, onClose]
  );
  const [groups] = useCategoryGroups();

  const { values, handleSubmit, setFieldValue, handleChange } = useFormik({
    onSubmit,
    initialValues: {
      name: '',
      brand: '',
      price: '',
      description: '',
      category: '',
      externalLink: '',
      templateCss: '',
      templateHtml: '',
      fieldOfView: '',
      minFieldOfView: '',
      maxFieldOfView: '',
      exposure: 1,
      shadowIntensity: 1,
      shadowSoftness: 1,
      orientation: [0, 0, 0],
      scale: [1, 1, 1],
      arPlacement: 'FLOOR',
      arScale: 'AUTO',
      isPublished: true,
      isAvailableTemplate: false,
    },
  });

  return (
    <Portal>
      <div className={styles.bulkSceneEditorForm}>
        <Scrollbars>
          <form className={styles.inner} onSubmit={handleSubmit}>
            <TextField
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="제품명"
            />
            <TextField
              name="brand"
              value={values.brand}
              onChange={handleChange}
              placeholder="브랜드"
            />
            <TextField
              name="price"
              value={values.price}
              onChange={handleChange}
              placeholder="가격"
            />
            <TextareaField
              name="description"
              value={values.description}
              onChange={handleChange}
              placeholder="설명"
            />
            <Dropdown
              value={values.category}
              onChange={(value) => {
                setFieldValue('category', value);
              }}
              placeholder="카테고리를 선택해주세요."
              groups={groups}
            />
            <FormColumn label="방향" additional="(Orientation)">
              <div className={styles.slider}>
                <VectorSlider
                  step={10}
                  labels={['X', 'Y', 'Z']}
                  ranges={[
                    [0, 360],
                    [0, 360],
                    [0, 360],
                  ]}
                  values={values.orientation}
                  onChange={(value: number[]) => {
                    setFieldValue('orientation', value);
                  }}
                />
              </div>
            </FormColumn>
            <FormColumn label="크기" additional="(Scale)">
              <div className={styles.slider}>
                <VectorSlider
                  labels={['X', 'Y', 'Z']}
                  ranges={[
                    [0, 1],
                    [0, 1],
                    [0, 1],
                  ]}
                  values={values.scale}
                  onChange={(value: number[]) => {
                    setFieldValue('scale', value);
                  }}
                />
              </div>
            </FormColumn>
            <FormColumn label="밝기" additional="(Exposure)">
              <div className={styles.slider}>
                <Slider
                  step={0.1}
                  range={[0, 2]}
                  value={values.exposure}
                  onChange={(value) => {
                    setFieldValue('exposure', value);
                  }}
                />
              </div>
            </FormColumn>
            <FormColumn label="그림자 강도" additional="(Shadow Intensity)">
              <div className={styles.slider}>
                <Slider
                  step={0.1}
                  range={[0, 2]}
                  value={values.shadowIntensity}
                  onChange={(value) => {
                    setFieldValue('shadowIntensity', value);
                  }}
                />
              </div>
            </FormColumn>
            <FormColumn label="그림자 부드러움" additional="(Shadow Softness)">
              <div className={styles.slider}>
                <Slider
                  step={0.1}
                  range={[0, 2]}
                  value={values.shadowSoftness}
                  onChange={(value) => {
                    setFieldValue('shadowSoftness', value);
                  }}
                />
              </div>
            </FormColumn>
            <FormColumn label="시야" additional="(Field of view)">
              <TextField value={values.fieldOfView} name="fieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
            </FormColumn>
            <FormColumn label="최소 시야" additional="(Field of view)">
              <TextField value={values.minFieldOfView} name="minFieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
            </FormColumn>
            <FormColumn label="최대 시야" additional="(Field of view)">
              <TextField value={values.maxFieldOfView} name="maxFieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
            </FormColumn>
            <FormColumn label="AR Placement">
              <Dropdown
                groups={[
                  {
                    options: [
                      { name: '바닥', value: 'FLOOR' },
                      { name: '벽', value: 'WALL' },
                    ],
                  },
                ]}
                value={values.arPlacement}
                onChange={(value) => {
                  setFieldValue('arPlacement', value);
                }}
                placeholder="AR Placement"
              />
            </FormColumn>
            <FormColumn label="AR Scale">
              <Dropdown
                groups={[
                  {
                    options: [
                      { name: '자동', value: 'AUTO' },
                      { name: '고정', value: 'FIXED' },
                    ],
                  },
                ]}
                value={values.arScale}
                onChange={(value) => {
                  setFieldValue('arScale', value);
                }}
                placeholder="AR Scale"
              />
            </FormColumn>
            <FormColumn label="외부 링크">
              <TextField
                name="externalLink"
                value={values.externalLink}
                onChange={handleChange}
                placeholder="https://"
              />
            </FormColumn>
            <div style={{ marginTop: 12 }}>
              <Switch
                label="게시여부"
                value={values.isPublished}
                onChange={(value) => {
                  setFieldValue('isPublished', value);
                }}
              />
              <Switch
                label="팝업 활성화"
                value={values.isAvailableTemplate}
                onChange={(value) => {
                  setFieldValue('isAvailableTemplate', value);
                }}
              />
            </div>
            {values.isAvailableTemplate && (
              <React.Fragment>
                <PopupTemplatePreview
                  css={values.templateCss}
                  html={values.templateHtml}
                />
                <div className={styles.monacoEditor}>
                  <Editor
                    theme="vs-dark"
                    height={300}
                    value={values.templateCss}
                    options={{
                      fontFamily: 'Rec Mono Linear, Monaco, Consolas, Verdana',
                    }}
                    onChange={(value) => {
                      setFieldValue('templateCss', value || '');
                    }}
                    language="css"
                  />
                </div>
                <div className={styles.monacoEditor}>
                  <Editor
                    theme="vs-dark"
                    height={300}
                    value={values.templateHtml}
                    options={{
                      fontFamily: 'Rec Mono Linear, Monaco, Consolas, Verdana',
                    }}
                    onChange={(value) => {
                      setFieldValue('templateHtml', value || '');
                    }}
                    language="html"
                  />
                </div>
              </React.Fragment>
            )}
            <div className={styles.actions}>
              <Button
                color="danger"
                type="button"
                variant="rounded"
                size="large"
                onClick={() => {
                  setVisibleConfirmDeletePopup(true);
                }}
              >
                삭제하기
              </Button>
              <Button
                loading={false}
                type="submit"
                variant="rounded"
                size="large"
              >
                저장하기
              </Button>
            </div>
          </form>
        </Scrollbars>
        <Modal
          visible={isVisibleConfirmDeletePopup}
          onClose={() => {
            setVisibleConfirmDeletePopup(false);
          }}
        >
          <div className={classNames(styles.modal, styles.confirmDeletePopup)}>
            <Typography component="h2">정말 삭제하시겠습니까?</Typography>
            <div className={styles.actions}>
              <Button
                color="danger"
                onClick={() => {
                  bulkDeleteScene({ variables: { data: { hashIds } } }).then(() => {
                    onClose();
                  });
                }}
              >
                네, 삭제합니다.
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </Portal>
  );
});

export default BulkSceneEditorForm;
