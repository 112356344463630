import React, { FC, memo } from 'react';
import styles from './FormColumn.module.scss';
import Typography from '../Typography/Typography';

interface Props {
  label?: React.ReactNode;
  additional?: React.ReactNode;
}

const FormColumn: FC<Props> = memo(({ label, additional, children }) => {
  return (
    <div className={styles.formColumn}>
      <div className={styles.header}>
        <Typography component="h3">{label}</Typography>
        {additional && <Typography component="p" variant="info" className={styles.additional}>{additional}</Typography>}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
});

export default FormColumn;
