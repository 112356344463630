import { useWindowScroll, useWindowSize } from 'react-use';
import { useEffect } from 'react';

interface UseLoadMoreOptions {
  threshold: number;
}

export function useLoadMore(callback: () => void, { threshold }: UseLoadMoreOptions = { threshold: 100 }) {
  const { y } = useWindowScroll();
  const { height } = useWindowSize();
  const currentY = y + height;

  useEffect(() => {
    if (document.body.scrollHeight <= currentY + threshold) {
      callback.call(null);
    }
  }, [currentY, callback]);
}
