import React, {FC, memo} from 'react'
import classNames from "classnames";

interface Props {
  x: number,
  y: number,
  className?: string
}

const Text: FC<Props> = memo(({x, y, className, children}) => {
  return (
    <text
      x={x}
      y={y}
      className={classNames(className)}
    >
      {children}
    </text>
  )
})
export default Text