import React, { FC, memo, useCallback } from 'react';
import styles from './FileInput.module.scss';

interface Props {
  onChange: (file: File) => void;
}

const FileInput: FC<Props> = memo(({ onChange }) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || e.currentTarget.files.length <= 0) {
      return;
    }

    onChange(e.currentTarget.files[0]);
    e.currentTarget.value = '';
  }, [onChange]);

  return (
    <div className={styles.fileInput}>
      <input type="file" onChange={handleChange} />
    </div>
  );
});

export default FileInput;
