import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Slider.module.scss';
import { useSpring, animated } from 'react-spring';
import FastVector from 'fast-vector';
import { useMutableMouse } from '../../hooks/useMutableMouse';
import Typography from '../Typography/Typography';
import { useMutableKeyState } from '../../hooks/useMutableKeyState';

interface Props {
  label?: string;
  step?: number;
  range: [number, number];
  value: number;
  onChange: (value: number) => void;
}

const Slider: FC<Props> = memo(({ label, step, range, value, onChange }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const requestAnimationRef = useRef<number | null>(null);

  const [translate, setTranslate] = useState<null | number>(null);
  const [isPressed, setPressed] = useState(false);

  const mouseDownPositionRef = useRef<FastVector>(FastVector.zero);
  const mouseMovePositionRef = useMutableMouse();

  const getKeyState = useMutableKeyState();

  const min = useMemo(() => Math.min(...range), [range]);
  const max = useMemo(() => Math.max(...range), [range]);
  const diff = useMemo(() => max - min, [min, max]);
  const vstep = useMemo(() => step || diff * 0.1, [step]);

  const percentage = useMemo(() => {
    return (value - min) / diff;
  }, [diff, min, max, value]);

  const percentageRef = useRef(percentage);

  const valueDisplay = useMemo(() => {
    return (min + diff * percentage).toFixed(2);
  }, [percentage, diff, min, max, value]);

  const prop = useSpring({
    xs: [translate, isPressed ? 1.4 : 1.0],
    percentage,
    config: { tension: 800, friction: 60 },
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    percentageRef.current = percentage;
    setTranslate(ref.current.offsetWidth * percentage);
  }, [percentage]);

  const onUpdate = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const diffX =
      mouseMovePositionRef.current.x - mouseDownPositionRef.current.x;

    let value = (diff * diffX) / ref.current.offsetWidth;

    if (getKeyState('Control') || getKeyState('Command')) {
      value /= vstep;
      value = Math.round(value);
      value *= vstep;
    }

    if (value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }

    onChange(value);
    requestAnimationRef.current = window.requestAnimationFrame(onUpdate);
  }, [diff, min, max, vstep]);

  const onMouseUp = useCallback(() => {
    setPressed(false);
  }, []);

  useEffect(() => {
    if (!isPressed) {
      if (requestAnimationRef.current !== null) {
        window.cancelAnimationFrame(requestAnimationRef.current);
      }

      return;
    }

    window.addEventListener('mouseup', onMouseUp);
    requestAnimationRef.current = window.requestAnimationFrame(onUpdate);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isPressed, onUpdate, onMouseUp]);

  return (
    <div className={styles.slider}>
      <div className={styles.header}>
        {label && (
          <Typography className={styles.label} component="p" align="left">
            {label}
          </Typography>
        )}
        <Typography component="p" variant="info" align="right">
          {valueDisplay}
        </Typography>
      </div>
      <div ref={ref} className={styles.inner}>
        <div className={styles.progressWrap}>
          <div className={styles.progressBackground} />
          <animated.div
            className={styles.progress}
            style={{
              width: prop.percentage.interpolate((p: number | null) =>
                p === null ? '' : `${p * 100}%`
              ),
            }}
          />
        </div>
        <animated.button
          type="button"
          style={{
            transform: (prop as any).xs.interpolate(
              (x: number, s: number) => `translateX(${x}px) scale(${s})`
            ),
          }}
          className={styles.cursor}
          onMouseDown={(e) => {
            e.preventDefault();
            mouseDownPositionRef.current = mouseMovePositionRef.current.clone();
            if (ref.current) {
              mouseDownPositionRef.current.x -=
                percentageRef.current * ref.current.offsetWidth;
            }
            setPressed(true);
          }}
        />
      </div>
    </div>
  );
});

export default Slider;
