import React, { FC, memo, useMemo } from 'react';
import styles from './DropzoneItem.module.scss';
import { FileInfo } from '../Dropzone/Dropzone';
import {
  MAX_BYTE,
  MAX_GIGABYTE,
  MAX_KILOBYTE,
  MAX_MEGABYTE,
} from '../../cores/constants';
import Typography from '../Typography/Typography';
import Spinner from '../Spinner/Spinner';
import classNames from 'classnames';

interface Props {
  fileInfo: FileInfo;
}

const DropzoneItem: FC<Props> = memo(
  ({ fileInfo: { size, name, status, dataUrl } }) => {
    const displaySize = useMemo(() => {
      if (size < MAX_BYTE) {
        return `${size}BYTE`;
      } else if (size < MAX_KILOBYTE) {
        return `${(size / MAX_BYTE).toFixed(1)}KB`;
      } else if (size < MAX_MEGABYTE) {
        return `${(size / MAX_KILOBYTE).toFixed(1)}MB`;
      } else if (size < MAX_GIGABYTE) {
        return `${(size / MAX_MEGABYTE).toFixed(1)}GB`;
      } else {
        return `${(size / MAX_GIGABYTE).toFixed(1)}TB`;
      }
    }, [size]);

    return (
      <div className={styles.dropzoneItem}>
        <div
          className={classNames(styles.thumbnail, {
            [styles.loaded]: status === 'loaded',
          })}
          style={{ background: dataUrl ? `url(${dataUrl})` : `` }}
        >
          {status === 'pending' && <Spinner />}
        </div>
        <Typography className={styles.name} component="p">
          {name}
        </Typography>
        <Typography className={styles.size} component="p" variant="info">
          {displaySize}
        </Typography>
      </div>
    );
  }
);

export default DropzoneItem;
