import { useLayoutEffect, useState } from 'react';

export function useWindowScroll() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useLayoutEffect(() => {
    const handleScroll = () => {
      setPosition({ x: window.scrollX, y: window.scrollY });
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return position;
}
