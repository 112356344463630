import {useMemo} from "react";


export function useCalculateDate(frequency: string): [dateTotalCount: number, after: Date, before: Date] {

  const frequencyNumber = useMemo(() => {
    switch (frequency) {
      case 'ONE_MONTH':
        return 1
      case 'THREE_MONTH':
        return 3
      case 'SIX_MONTH':
        return 6
      default:
        return 1
    }
  }, [frequency])

  const now = new Date();

  const after: number = useMemo(() => {
    return now.setDate(now.getDate() + 1)
  }, [now])

  const before: any = useMemo(() => {
    return new Date(now.getFullYear(), now.getMonth() - frequencyNumber, now.getDate() - 1)
  }, [now, frequency])

  const dateTotalCount = useMemo(() => {
    return ((after - before) / (60 * 60 * 1000 * 24));
  }, [after, before])


  return [dateTotalCount, new Date(after), before]
}