import { useKeepRecoilValue } from './useKeepRecoilValue';
import { CategoryState, getCategory } from '../atoms/selectors/getCategory';
import { useMemo } from 'react';
import { OptionGroup } from '../component-system/Dropdown/Dropdown';

export function useCategoryGroups(): [OptionGroup[], CategoryState] {
  const [categories] = useKeepRecoilValue(getCategory({}), []);
  const groups = useMemo(
    () =>
      categories.map((category) => ({
        name: category.name,
        options: category.children.map((child) => ({
          name: child.name,
          value: child.hashId,
        })),
      })),
    [categories]
  );

  return [groups, categories]
}
