import React, { FC, memo, useMemo } from 'react';
import styles from './SceneListItem.module.scss';
import { Scene } from '../../cores/schema';
import { useDateTimeDisplay } from '../../hooks/useDateTimeDisplay';
import Typography from '../../component-system/Typography/Typography';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Ink from 'react-ink';
import Checkbox from '../../component-system/Checkbox/Checkbox';
import { useThumbnail } from '../../hooks/useThumbnail';

interface Props {
  data: Scene;
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

const SceneListItem: FC<Props> = memo(
  ({
    data: { hashId, name, brand, price, description, category, isPublished, createdAt, thumbnail },
    isChecked,
    onChange,
  }) => {
    const createdAtDisplay = useDateTimeDisplay(createdAt, 'yy/MM/dd');
    const thumbnailSrc = useThumbnail(thumbnail);

    const renderedCategory = useMemo(() => {
      if (!category || !category.parent) {
        return null;
      }

      return (
        <span className={styles.categoryStep}>
          {category.parent.name}
          <MdKeyboardArrowRight />
          {category.name}
        </span>
      );
    }, [category]);

    return (
      <Link
        to={{ pathname: `/scenes/${hashId}` }}
        className={styles.sceneListItem}
      >
        <div
          className={classNames(styles.column, styles.checkbox)}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Checkbox value={isChecked} onChange={onChange} />
        </div>
        <div className={classNames(styles.column, styles.descriptor)}>
          <div className={styles.thumbnail} style={{ backgroundImage: thumbnailSrc ? `url(${thumbnailSrc})` : '' }} />
          <div className={styles.inner}>
            <Typography component="h3" className={styles.name}>
              {name}
            </Typography>
            {brand && (
              <Typography component="p"
                          className={styles.brand}
              >
                {brand}
              </Typography>
            )}
            {price && (
              <Typography component="p"
                          className={styles.price}
              >
                {price}
              </Typography>
            )}
            {description && description.trim() && (
              <Typography
                component="p"
                variant="info"
                className={styles.description}
              >
                {description}
              </Typography>
            )}
          </div>
        </div>
        <div className={classNames(styles.column, styles.createdAt)}>
          <Typography component="p" variant="info">
            {createdAtDisplay}
          </Typography>
        </div>
        <div className={classNames(styles.column, styles.category)}>
          <Typography component="p" variant="info">
            {renderedCategory}
          </Typography>
        </div>
        <div className={classNames(styles.column, styles.isPublished)}>
          <Typography component="p" variant="info">
            {isPublished ? '공개' : '비공개'}
          </Typography>
        </div>
        <Ink />
      </Link>
    );
  }
);

export default SceneListItem;
