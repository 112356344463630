import React, {forwardRef, HTMLAttributes} from "react"
import classNames from "classnames";

interface Props {
  x: number
  y: number
  radius: number
  className?: string
}

const Circle = forwardRef<SVGCircleElement,
  Props & HTMLAttributes<SVGCircleElement>>
(({
    x,
    y,
    radius,
    className
  }, ref
) => {
  return (
    <circle
      ref={ref}
      className={classNames(className)}
      cx={x}
      cy={y}
      r={radius}
    />
  )
})

export default Circle