import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CategoriesForm.module.scss';
import { CategoryState } from '../../atoms/selectors/getCategory';
import { animated, useSprings } from 'react-spring';
import CategoryItemForm from '../CategoryItemForm/CategoryItemForm';
import { useDrag } from '@use-gesture/react';
import { clamp, chain, map } from 'lodash';
// @ts-ignore
import swap from 'lodash-move';
import { Category } from '../../cores/schema';

export interface Item {
  index: number;
  hashId: string;
  height: number;
  children: Item[];
}

const serialize = (categories: Category[]): Item[] => {
  return map(categories, (category, index) => {
    const height = category.children ? (category.children.length + 1) * 45 + 12 : 45;

    return {
      index,
      hashId: category.hashId,
      height,
      children: serialize(category.children),
    };
  });
};

const fn = (
  items: Item[],
  originalItems: Item[],
  immediate = false,
  active = false,
  originalIndex = 0,
  currentIndex = 0,
  y = 0
) => {
  return (index: number) => {
    if (active && index === originalIndex) {
      return {
        y:
          y +
          chain(originalItems)
            .slice(0, currentIndex)
            .map((item) => item.height)
            .sum()
            .value(),
        scale: 1.1,
        zIndex: 1,
        shadow: 15,
        immediate: (key: string) => key === 'zIndex',
      };
    }

    return {
      y: chain(items)
        .slice(
          0,
          items.findIndex((item) => item.index === index)
        )
        .map((item) => item.height)
        .sum()
        .value(),
      scale: 1,
      zIndex: 0,
      shadow: 1,
      immediate,
    };
  };
};

interface Props {
  parent?: boolean;
  categories: CategoryState;
  onChange?: (items: Item[]) => void;
}

const CategoriesForm: FC<Props> = memo(({ categories, onChange, parent }) => {
  const items = useRef<Item[]>([]);
  const [height, setHeight] = useState(0);
  const [springs, api] = useSprings(
    categories.length,
    fn(items.current, items.current)
  );
  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const currentIndex = items.current.findIndex(
      (order) => order.index === originalIndex
    );

    const averageHeight = chain(items.current).map(item => item.height).mean().value();
    const currentRow = clamp(
      Math.round((currentIndex * averageHeight + y) / averageHeight),
      0,
      categories.length - 1
    );

    const newOrder = swap(items.current, currentIndex, currentRow);
    api.start(
      fn(
        newOrder,
        items.current,
        false,
        active,
        originalIndex,
        currentIndex,
        y
      )
    );
    if (!active) {
      if (
        JSON.stringify(items.current) !== JSON.stringify(newOrder) &&
        onChange
      ) {
        onChange(newOrder);
      }

      items.current = newOrder;
    }
  });

  useEffect(() => {
    items.current = serialize(categories);
    api.start(fn(items.current, items.current, true));
    setHeight(
      chain(items.current)
        .map((item) => item.height)
        .sum()
        .value()
    );
  }, [categories]);

  return (
    <div className={styles.categoriesForm}>
      <div className={styles.items} style={{ height }}>
        {springs.map(({ zIndex, shadow, y, scale }, i) => (
          <animated.div
            key={i}
            className={styles.categoryBehavior}
            style={{
              zIndex,
              boxShadow: shadow.to(
                (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
              ),
              y,
            }}
            children={
              <CategoryItemForm
                parent={parent}
                category={categories[i]}
                {...bind(i)}
                onChange={(childItems) => {
                  const childIndex = items.current.findIndex(
                    (item) => item.index === i
                  );
                  if (childIndex !== -1 && onChange) {
                    items.current[childIndex].children = childItems;
                    onChange(items.current);
                  }
                }}
              />
            }
          />
        ))}
      </div>
    </div>
  );
});

export default CategoriesForm;
