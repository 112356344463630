import React, { FC, memo } from 'react';
import styles from './Aside.module.scss';
import { Link } from 'react-router-dom';
import AsideItem from '../AsideItem/AsideItem';
import { MdDashboard, MdViewList, MdPhotoCamera, MdPeople } from 'react-icons/md';

interface Props {}

const Aside: FC<Props> = memo(() => {
  return (
    <div className={styles.aside}>
      <div className={styles.logo}>
        <Link to="/">Place AR</Link>
      </div>
      <div className={styles.asideItems}>
        <AsideItem path="/dashboard">
          <MdDashboard /> 대시보드
        </AsideItem>
        <AsideItem path="/categories">
          <MdViewList /> 카테고리
        </AsideItem>
        <AsideItem path="/scenes">
          <MdPhotoCamera /> 프로덕트
        </AsideItem>
        <AsideItem path="/information">
          <MdPeople /> 내정보
        </AsideItem>
      </div>
    </div>
  );
});

export default Aside;
