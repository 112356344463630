import React, { FC, memo } from 'react';
import styles from './Box.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const Box: FC<Props> = memo(({ className, children }) => {
  return <div className={classNames(styles.box, className)}>{children}</div>;
});

export default Box;
