import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import styles from './SceneCreateForm.module.scss';
import Dropzone from '../../component-system/Dropzone/Dropzone';
import TextField from '../../component-system/TextField/TextField';
import { FormikHelpers, useFormik } from 'formik';
import Switch from '../../component-system/Switch/Switch';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import path from 'path';
import Button from '../../component-system/Button/Button';
import { useMutation } from '@apollo/client';
import { UPLOAD_SCENE } from '../../cores/mutations';
import { useHistory } from 'react-router-dom';
import { client } from 'src/cores/client';

interface Props {
  hashId?: string;
  onComplete?: () => void;
}

interface SceneFormValues {
  hashId: string;
  name: string;
  files: File[];
  isPublished: boolean;
}

const SceneCreateForm: FC<Props> = memo(({ hashId, onComplete }) => {
  const history = useHistory();
  const [isSubmit, setSubmit] = useState(false);
  const [uploadScene, { data, loading, error }] = useMutation(UPLOAD_SCENE, {
    errorPolicy: 'all',
    client,
  });

  console.log(error)

  const isUpdatedForm = !!hashId;

  const onSubmit = useCallback(
    (
      values: SceneFormValues,
      { setFieldError }: FormikHelpers<SceneFormValues>
    ) => {
      if (isSubmit) {
        return;
      }

      if (!values.name.trim()) {
        setFieldError('name', '제품명을 입력해주세요.');
        return;
      }

      const gltfFiles = values.files.filter(
        (file) => path.extname(file.name) === '.gltf'
      );

      if (gltfFiles.length <= 0) {
        alert('gltf 파일이 없습니다.');
        return;
      }

      if (gltfFiles.length > 1) {
        alert('gltf 파일은 한개만 업로드 해주세요.');
        return;
      }

      const data: Partial<SceneFormValues> = {
        ...values
      };

      if (hashId) {
        delete data['name'];
        delete data['isPublished'];
      }

      try {
        uploadScene({variables: {data}}).then((payload) => {
          if (onComplete) {
            onComplete();
          }
        });
      } catch (e) {
        alert(e);
      }
      setSubmit(true);
    },
    [isSubmit, onComplete]
  );

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik<SceneFormValues>({
      onSubmit,
      initialValues: {
        hashId: hashId || '',
        name: '',
        files: [],
        isPublished: true,
      },
    });

  useEffect(() => {
    if (hashId) {
      setFieldValue('hashId', hashId);
    }
  }, [hashId]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.uploadScene) {
      return;
    }

    history.replace(`/scenes/${data.uploadScene.hashId}`);
  }, [data, history]);

  useEffect(() => {
    if (!error) {
      return;
    }

    alert(error);
  }, [error]);

  return (
    <div className={styles.sceneCreateForm}>
      <form onSubmit={handleSubmit}>
        {!isUpdatedForm && (
          <FormColumn label="이름">
            <TextField
              name="name"
              placeholder="이름을 입력해주세요."
              value={values.name}
              onChange={handleChange}
              validationText={errors.name}
            />
          </FormColumn>
        )}
        <FormColumn label="모델 파일">
          <Dropzone
            onDropGltfFile={(file) => {
              if (!values.name.trim()) {
                setFieldValue(
                  'name',
                  file.name.replace(path.extname(file.name), '')
                );
              }
            }}
            onChange={(files) => {
              setFieldValue('files', files);
            }}
          />
        </FormColumn>
        {!isUpdatedForm && (
          <FormColumn label="게시여부">
            <Switch
              value={values.isPublished}
              onChange={(value) => {
                setFieldValue('isPublished', value);
              }}
            />
          </FormColumn>
        )}
        <div className={styles.actions}>
          <Button
            loading={loading}
            type="submit"
            variant="rounded"
            size="large"
          >
            업로드
          </Button>
        </div>
      </form>
    </div>
  );
});

export default SceneCreateForm;
