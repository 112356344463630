import React, { FC, memo } from 'react';
import Category from '../../components/Category/Category';
import Layout from '../../components/Layout/Layout';
import styles from './CategoryPage.module.scss';

interface Props {}

const CategoryPage: FC<Props> = memo(() => {
  return (
    <Layout className={styles.CategoryLayout}>
      <Category />
    </Layout>
  );
});

export default CategoryPage;
