import React, { FC, memo } from 'react';
import styles from './Modal.module.scss';
import { Portal } from 'react-portal';
import Button from '../Button/Button';
import { MdClose } from 'react-icons/md';
import { useClickOutside } from '../../hooks/useClickOutside';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Modal: FC<Props> = memo(({ visible, children, onClose }) => {
  const [targetRef] = useClickOutside(() => {
    onClose();
  });

  if (!visible) {
    return null;
  }

  return (
    <Portal>
      <div
        className={styles.dimmer}
        onClick={() => {
          onClose();
        }}
      />
      <div className={styles.modal}>
        <div ref={targetRef} className={styles.inner}>
          <div className={styles.actions}>
            <button
              className={styles.closeButton}
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              <MdClose />
            </button>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  );
});

export default Modal;
