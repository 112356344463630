import React, {forwardRef} from 'react'
import classNames from "classnames";

interface Props {
  x1: number
  y1: number
  x2: number
  y2: number
  className?: string
}

const Line = forwardRef<SVGLineElement, Props>(({x1, x2, y1, y2, className}, ref) => {
  return (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      className={classNames(className)}
      ref={ref}
    />
  )
})

export default Line