import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './CategoryUpsertModal.module.scss';
import Modal from '../../component-system/Modal/Modal';
import { Category } from '../../cores/schema';
import TextField from '../../component-system/TextField/TextField';
import { useFormik } from 'formik';
import { get } from 'lodash';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import Typography from '../../component-system/Typography/Typography';
import Button from '../../component-system/Button/Button';
import { UPSERT_CATEGORY } from '../../cores/mutations';
import { useMutation } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { triggerState } from '../../atoms/selectors/trigger';

interface Props {
  visible: boolean;
  onClose: () => void;
  order?: number;
  hashId?: string;
  parent?: Category;
  category?: Category;
}

interface CategoryUpsertFormValues {
  name: string;
}

const CategoryUpsertModal: FC<Props> = memo(
  ({ visible, onClose, order, parent, category, hashId }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [upsertCategory] = useMutation(UPSERT_CATEGORY);
    const [isSubmit, setSubmit] = useState(false);
    const [, updateTriggerState] = useRecoilState(triggerState('getCategory'));

    const onSubmit = useCallback(
      (values: CategoryUpsertFormValues) => {
        if (isSubmit) {
          return;
        }

        if (!values.name.trim()) {
          alert('이름을 입력해주세요.');
          return;
        }

        const data: {
          name: string;
          order?: number;
          parent?: string;
          hashId?: string;
        } = {
          name: values.name.trim(),
        };

        if (typeof order === 'number') {
          data.order = order;
        }

        if (parent) {
          data.parent = parent.hashId;
        }

        if (hashId) {
          data.hashId = hashId;
        }

        setSubmit(true);
        upsertCategory({ variables: { data: data } })
          .then(() => {})
          .finally(() => {
            onClose();
            setSubmit(false);
            updateTriggerState(Date.now());
          });
      },
      [order, parent, hashId, upsertCategory, isSubmit]
    );

    const { values, setFieldValue, handleChange, handleSubmit } =
      useFormik<CategoryUpsertFormValues>({
        onSubmit,
        initialValues: {
          name: get(category, 'name', ''),
        },
      });

    const titleDisplay = useMemo(() => {
      if (category) {
        return `"${category.name}" 수정하기`
      }

      if (!parent) {
        return '상위 카테고리 추가하기';
      }

      return `"${parent.name}" 하위 카테고리 추가하기`;
    }, [parent, category]);


    const buttonTextDisplay = useMemo(() => {
      if (category) {
        return '수정하기';
      }

      return '추가하기'
    }, [category])

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      if (!visible) {
        return;
      }

      inputRef.current.select();
    }, [visible])

    useEffect(() => {
      setFieldValue('name', get(category, 'name', ''));
    }, [category, setFieldValue]);

    return (
      <Modal visible={visible} onClose={onClose}>
        <div className={styles.categoryUpsertModal}>
          <form onSubmit={handleSubmit}>
            <div className={styles.header}>
              <Typography component="h2">{titleDisplay}</Typography>
            </div>
            <FormColumn label="제품명">
              <TextField
                ref={inputRef}
                value={values.name}
                name="name"
                onChange={handleChange}
              />
            </FormColumn>
            <FormColumn>
              <div className={styles.actions}>
                <Button size="medium" variant="rounded">
                  {buttonTextDisplay}
                </Button>
              </div>
            </FormColumn>
          </form>
        </div>
      </Modal>
    );
  }
);

export default CategoryUpsertModal;
