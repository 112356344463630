import React, { HTMLAttributes, forwardRef } from 'react';
import styles from './TextareaField.module.scss';
import Typography from '../Typography/Typography';
import classNames from 'classnames';

interface Props {
  className?: string;
  name?: string;
  value?: string;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  validationText?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextareaField = forwardRef<
  HTMLTextAreaElement,
  Props & HTMLAttributes<HTMLTextAreaElement>
>(
  (
    {
      className,
      label,
      name,
      value,
      size = 'medium',
      onChange,
      validationText,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div
        className={classNames(styles.textareaField, className, styles[size], {
          [styles.isError]: !!validationText,
        })}
      >
        {label && (
          <Typography className={styles.label} component="p">
            {label}
          </Typography>
        )}
        <textarea
          ref={ref}
          name={name}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
        {validationText && (
          <Typography className={styles.validationText} component="p">
            {validationText}
          </Typography>
        )}
      </div>
    );
  }
);

export default TextareaField;
