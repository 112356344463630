import React, { FC, memo } from 'react';
import styles from './App.module.scss';
import Header from '../Header/Header';
import { Route, Switch } from 'react-router-dom';
import { useRedirectRules } from '../../hooks/useRedirectRules';
import LoginPage from '../../pages/LoginPage/LoginPage';
import HomePage from '../../pages/HomePage/HomePage';
import Aside from '../Aside/Aside';
import SceneListPage from '../../pages/SceneListPage/SceneListPage';
import SceneDetailPage from '../../pages/SceneDetailPage/SceneDetailPage';
import CategoryPage from '../../pages/CategoryPage/CategoryPage';
import DashBoardPage from '../../pages/DashBoardPage/DashBoardPage';
import InformationPage from '../../pages/InformationPage/InformationPage';

interface Props {}

const App: FC<Props> = memo(() => {
  useRedirectRules();

  return (
    <div className={styles.app}>
      <div className={styles.main}>
        <div className={styles.aside}>
          <Aside />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <Header />
          </div>
          <div className={styles.body}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/information" component={InformationPage} />
              <Route path="/dashboard" component={DashBoardPage} />
              <Route path="/categories" component={CategoryPage} />
              <Route exact path="/scenes" component={SceneListPage} />
              <Route path="/scenes/:hashId" component={SceneDetailPage} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
});

export default App;
