import React, { FC, memo } from 'react';
import styles from './Spinner.module.scss';
import classNames from 'classnames';
interface Props {
  className?: string;
  size?: 'small' | 'medium';
  variant?: 'normal' | 'white';
}

const Spinner: FC<Props> = memo(
  ({ className, size = 'small', variant = 'normal' }) => {
    return (
      <div
        className={classNames(
          styles.spinner,
          styles[size],
          styles[variant],
          className
        )}
      />
    );
  }
);

export default Spinner;
