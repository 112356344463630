import { useRecoilValue } from 'recoil';
import { tokenState } from '../atoms/tokenState';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function useRedirectRules() {
  const token = useRecoilValue(tokenState);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (token !== null && pathname === '/login') {
      history.replace('/dashboard');
    }

    if (token === null && pathname !== '/' && pathname !== '/login') {
      history.replace('/login');
    }
  }, [token, pathname, history]);
}
