import {
  createRef,
  FC,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import styles from "./DashboardSvg.module.scss"
import Rect from "./SvgNode/Rect"
import Circle from "./SvgNode/Circle";
import Path from "./SvgNode/Path";
import Text from "./SvgNode/Text";
import Line from "./SvgNode/Line";
import _ from "lodash";
import {isNaN} from "formik";
import classNames from "classnames";

interface Props {
  parentBox: RefObject<HTMLDivElement>
  data: { [index: string]: number }
  frequency: string
}

const DashboardSvg: FC<Props> = memo(({parentBox, data, frequency}) => {
  const dotRefs = useRef<RefObject<SVGCircleElement>[]>([]);
  const lineRefs = useRef<RefObject<SVGLineElement>[]>([]);
  const countRefs = useRef<RefObject<SVGTextElement>[]>([]);
  const dateRefs = useRef<RefObject<SVGTextElement>[]>([]);
  const pathRef = useRef<SVGPathElement>(null)

  dotRefs.current = useMemo(() => Object.entries(data).map((e, i) => createRef()), [data])
  lineRefs.current = useMemo(() => Object.entries(data).map((e, i) => createRef()), [data])
  countRefs.current = useMemo(() => Object.entries(data).map((e, i) => createRef()), [data])
  dateRefs.current = useMemo(() => Object.entries(data).map((e, i) => createRef()), [data]);

  const organizedData = useMemo(() => Object.entries(data), [data])
  const [textWidth, setTextWidth] = useState<number>(0)
  const [numberOfText, setNumberOfText] = useState<number>(50);
  const [animate, setAnimate] = useState<boolean>(false);

  const [svgProps, setSvgProps] = useState({
    width: 0,
    height: 0,
    paddingX: 50,
    paddingY: 30,
    drawingSectionX: 0,
    drawingSectionY: 0,
    offsetBottom: 40,
    radius: 5,
    horizon: 6,
    rotate: 50,
    duration: 2000,

  })

  const handleMouseOver = (
    ref: RefObject<SVGCircleElement>,
    ref2: RefObject<SVGLineElement>,
    ref3: RefObject<SVGTextElement>
  ) => {
    if (ref.current && ref2.current && ref3.current) {
      ref.current.style.display = "block"
      ref2.current.style.display = "block"
      ref3.current.style.display = "block"
    }
  }

  const handleMouseOverOut = (
    ref: RefObject<SVGCircleElement>,
    ref2: RefObject<SVGLineElement>,
    ref3: RefObject<SVGTextElement>
  ) => {
    if (ref.current && ref2.current && ref3.current) {
      ref.current.style.display = ""
      ref2.current.style.display = ""
      ref3.current.style.display = ""
    }
  }

  const commonData = useMemo(() => {
    const rectWidth = svgProps.drawingSectionX / organizedData.length;
    const positionY = svgProps.drawingSectionY - svgProps.offsetBottom;
    const positionX = rectWidth;


    return [rectWidth, positionY, positionX]
  }, [svgProps, organizedData])

  const calculateHorizonData = useMemo(() => {
    const [, positionY,] = commonData
    const maxValue = Math.max(0, ...Object.values(data));
    const baseline = maxValue * 2;

    let horizonMaxValue: string = "0"

    if (String(baseline).length === String(maxValue).length) {
      horizonMaxValue = String(baseline).charAt(0) + new Array(String(maxValue).length - 1).fill(0).join("");
    }
    if (String(baseline).length > String(maxValue).length) {
      horizonMaxValue = "1" + new Array(String(maxValue).length).fill(0).join("");
    }

    return [parseInt(horizonMaxValue), (positionY / (parseInt(horizonMaxValue) + (parseInt(horizonMaxValue) / (svgProps.horizon - 1))))]

  }, [data])

  const rectNodes = useMemo(() => {
    const [rectWidth, positionY] = commonData

    return organizedData.map((e, i) => {
      return (
        <Rect
          key={i}
          x={rectWidth * i + svgProps.paddingX - rectWidth / 2}
          y={svgProps.paddingY}
          width={rectWidth}
          height={positionY - 20}
          className={styles.rect}
          onMouseOver={() => {
            handleMouseOver(
              dotRefs.current[i],
              lineRefs.current[i],
              countRefs.current[i]
            )
          }}
          onMouseOut={() => {
            handleMouseOverOut(
              dotRefs.current[i],
              lineRefs.current[i],
              countRefs.current[i]
            )
          }}
        />
      )
    });

  }, [organizedData, svgProps])

  const dotNode = useMemo(() => {
    const [, positionY, positionX] = commonData
    const lineHeight = positionY / svgProps.horizon
    const [, onePixel] = calculateHorizonData;

    return organizedData.map((e, i) => {
      return (
        <g key={i}>
          <Circle
            x={positionX * i + (svgProps.paddingX)}
            y={positionY - e[1] * onePixel}
            radius={svgProps.radius}
            className={styles.dot}
            ref={dotRefs.current[i]}
          />
          <Line
            x1={positionX * i + (svgProps.paddingX)}
            y1={positionY}
            x2={positionX * i + (svgProps.paddingX)}
            y2={positionY - lineHeight * (svgProps.horizon - 1)}
            className={styles.verticalIndicator}
            ref={lineRefs.current[i]}
          />
          <text
            x={positionX * i + (svgProps.paddingX) + 10}
            y={positionY - e[1] * onePixel - 10}
            className={styles.count}
            ref={countRefs.current[i]}
          >
            {e[1]}
          </text>
        </g>
      )
    });
  }, [organizedData, svgProps, animate])


  const pathNode = useMemo(() => {
    const [, positionY, positionX] = commonData
    const [, onePixel] = calculateHorizonData;

    const data = organizedData.map((e, i) => {
      if (organizedData[i + 1]) {
        return (
          `${positionX * i + (svgProps.paddingX)}, ${positionY - e[1] * onePixel},${positionX * (i + 1) + (svgProps.paddingX)}, ${positionY - organizedData[i + 1][1] * onePixel} `
        )
      } else {
        return (
          `${positionX * i + (svgProps.paddingX)}, ${positionY - e[1] * onePixel},${positionX * (i + 1) + (svgProps.paddingX)}, ${positionY}`
        )
      }
    }).join("")

    return (
      <Path
        d={`M${data}`}
        className={styles.path}
        ref={pathRef}
      />
    );
  }, [organizedData, svgProps])

  const horizonIndicator = useMemo(() => {
    const [rectWidth, positionY] = commonData
    const lineHeight = positionY / svgProps.horizon
    const [max] = calculateHorizonData

    if (isNaN(rectWidth) || rectWidth === Infinity) {
      return
    }

    return new Array(svgProps.horizon).fill(1).map((e, i) => {
      return (
        <g key={i}>
          <Text
            x={svgProps.paddingX - 30}
            y={Math.floor(positionY - lineHeight * i) + 2}
            className={styles.horizonText}
          >
            {Math.floor(max - (max - (max / (svgProps.horizon - 1) * i)))}
          </Text>
          <Line
            x1={svgProps.paddingX}
            y1={positionY - lineHeight * i}
            x2={svgProps.drawingSectionX + svgProps.paddingX}
            y2={positionY - lineHeight * i}
            className={styles.horizonIndicator}
          />
        </g>
      )
    })
  }, [organizedData, svgProps])

  const date = useMemo(() => {
    const [, positionY, positionX] = commonData
    const interval = Math.floor(organizedData.length / numberOfText) + 1

    return organizedData.map((e, i) => {
      return (
        <text
          key={i}
          transform={`translate(${positionX * i + (svgProps.paddingX)},${positionY + 20}) rotate(${svgProps.rotate})`}
          className={classNames(styles.date, {[styles.hide]: !!(i % interval)})}
          ref={dateRefs.current[i]}
        >
          {e[0]}
        </text>
      )
    })
  }, [organizedData, commonData, numberOfText])


  const handleResize = _.throttle(useCallback(() => {
    if (!parentBox.current) {
      return;
    }

    setSvgProps({
      ...svgProps,
      width: parentBox.current.clientWidth,
      height: parentBox.current.clientHeight,
      drawingSectionX: parentBox.current.clientWidth - (2 * svgProps.paddingX),
      drawingSectionY: parentBox.current.clientHeight - (2 * svgProps.paddingY)

    })
  }, [svgProps]), 250)


  const pathAnimation = useCallback(() => {

    if (!pathRef.current) {
      return
    }

    pathRef.current.style.strokeDasharray = String(pathRef.current.getTotalLength());
    pathRef.current.style.strokeDashoffset = String(pathRef.current.getTotalLength());

    pathRef.current.animate([
      {strokeDashoffset: String(pathRef.current.getTotalLength())},
      {strokeDashoffset: 0},
    ], {
      duration: svgProps.duration
    })

    setTimeout(() => {
      if (!pathRef.current) {
        return
      }
      pathRef.current.style.strokeDasharray = '0'
      pathRef.current.style.strokeDashoffset = '0'
      setAnimate(true)
    }, svgProps.duration)

  }, [pathRef.current, data])


  useLayoutEffect(() => {
    pathAnimation()
  }, [organizedData])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])


  useEffect(() => {
    if (!parentBox.current) {
      return;
    }

    setSvgProps({
      ...svgProps,
      width: parentBox.current.clientWidth,
      height: parentBox.current.clientHeight,
      drawingSectionX: parentBox.current.clientWidth - (2 * svgProps.paddingX),
      drawingSectionY: parentBox.current.clientHeight - (2 * svgProps.paddingY)
    })
  }, [parentBox])

  useEffect(() => {
    if (!dateRefs.current) {
      return
    }
    let textTotalWidth = 0

    dateRefs.current.forEach((e) => {
      textTotalWidth += (e.current?.clientWidth as number) * Math.cos(svgProps.rotate)
    })

    if (textTotalWidth) {
      setTextWidth(Math.floor(textTotalWidth / organizedData.length))
    }
  }, [dateRefs.current, organizedData])


  useEffect(() => {
    if (!svgProps.drawingSectionX || !textWidth) {
      return
    }

    setNumberOfText(Math.floor(svgProps.drawingSectionX / textWidth));
  }, [textWidth, svgProps])

  return (
    <svg className={styles.svg} height={svgProps.height} width={svgProps.width}>
      <g>
        {horizonIndicator}
      </g>
      <g>
        {pathNode}
      </g>
      <g>
        {dotNode}
      </g>
      <g>
        {date}
      </g>
      <g>
        {animate && rectNodes}
      </g>
    </svg>

  )
})

export default DashboardSvg
