import React, { HTMLAttributes, forwardRef } from 'react';
import styles from './TextField.module.scss';
import Typography from '../Typography/Typography';
import classNames from 'classnames';

interface Props {
  readonly?: boolean;
  className?: string;
  name?: string;
  type?: string;
  value?: string;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  validationText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextField = forwardRef<
  HTMLInputElement,
  Props & HTMLAttributes<HTMLInputElement>
>(
  (
    {
      readonly,
      className,
      type,
      label,
      name,
      value,
      size = 'medium',
      onChange,
      validationText,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div
        className={classNames(styles.textField, className, styles[size], {
          [styles.isError]: !!validationText,
          [styles.isReadonly]: !!readonly,
        })}
      >
        {label && (
          <Typography className={styles.label} component="p">
            {label}
          </Typography>
        )}
        <input
          readOnly={readonly}
          ref={ref}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
        {validationText && (
          <Typography className={styles.validationText} component="p">
            {validationText}
          </Typography>
        )}
      </div>
    );
  }
);

export default TextField;
