import React, { FC, memo } from 'react';
import styles from './DropdownOption.module.scss';
import { Option } from '../Dropdown/Dropdown';
import Typography from '../Typography/Typography';
import classNames from 'classnames';
import Ink from 'react-ink';

interface Props {
  option: Option;
  onClick: (value: string) => void;
  isSelected: boolean;
}

const DropdownOption: FC<Props> = memo(({ option, onClick, isSelected }) => {
  return (
    <div
      className={classNames(styles.dropdownOption, {
        [styles.isSelected]: isSelected,
      })}
      onClick={() => {
        onClick(option.value);
      }}
    >
      <Typography component="p">{option.name}</Typography>
      <Ink />
    </div>
  );
});

export default DropdownOption;
