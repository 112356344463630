import {gql} from '@apollo/client';

export const categoryFragment = `
  fragment CategoryBody on Category {
    count
    hashId
    name
    parent {
      count
      hashId
      name
      updatedAt
      createdAt
    }
    updatedAt
    createdAt
    children {
      count
      hashId
      name
      parent {
        count
        hashId
        name
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    getCategories {
      count
      hashId
      name
      parent {
        count
        hashId
        name
        createdAt
      }
      order
      createdAt
      children {
        count
        hashId
        name
        order
        parent {
          hashId
        }
        createdAt
      }
    }
  }
`;

export const GET_DASHBOARD = gql`
  query ( $data : GetDashboardInput! ) {
    getDashboard( data : $data ){
      hits
    }
  }
`

export const GET_SCENES = gql`
  query ($first: Int!, $after: String, $search: String, $category: ID) {
    getScenes(first: $first, after: $after, search: $search, category: $category) {
      leftCount
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          hashId
          brand
          price
          thumbnail
          category {
            hashId
            name
            parent {
              name
            }
          }
          isPublished
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_SCENE = gql`
  query ($hashId: ID!) {
    getScene(hashId: $hashId) {
      hashId
      category {
        hashId
        name
      }
      assetGroups {
        hashId
        error
        status
        assets {
          hashId
          key
          createdAt
        }
        createdAt
      }
      thumbnail
      templateCss
      templateHtml
      externalLink
      shadowIntensity
      shadowSoftness
      exposure
      scale
      orientation
      fieldOfView
      minFieldOfView
      maxFieldOfView
      arPlacement
      arScale
      isPublished
      name
      brand
      price
      description
      createdAt
      updatedAt
      isAvailableTemplate
    }
  }
`;

export const WHOAMI = gql`
  query {
    whoami {
      profile
      nickname
      isAvailableCollection
    }
  }
`;
