import React, { FC, memo, useMemo, useState } from 'react';
import styles from './Dropdown.module.scss';
import Typography from '../Typography/Typography';
import { MdArrowDropDown } from 'react-icons/md';
import { Portal } from 'react-portal';
// @ts-ignore
import useDimensions from 'react-use-dimensions';
import { Scrollbars } from 'react-custom-scrollbars';
import DropdownGroup from '../DropdownGroup/DropdownGroup';
import DropdownOption from '../DropdownOption/DropdownOption';
import { useClickOutside } from '../../hooks/useClickOutside';
import Ink from 'react-ink';
import { useWindowScroll } from '../../hooks/useWindowScroll';

export interface OptionGroup {
  name?: string;
  options: Option[];
}

export interface Option {
  name: string;
  value: string;
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  groups: OptionGroup[];
}

const Dropdown: FC<Props> = memo(({ value, placeholder, groups, onChange }) => {
  const [rootRef, { x, y, width, height }] = useDimensions();
  const [isVisible, setVisible] = useState(false);
  const [targetRef] = useClickOutside(() => {
    setVisible(false);
  });

  const { y: scrollY } = useWindowScroll();

  const popupHeight = useMemo(
    () =>
      Math.min(groups.filter((group) => group.name).length * 31 +
        groups.flatMap((group) => group.options).length * 43, 400),
    [groups]
  );

  const selectedOption = useMemo(() => {
    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].options.length; j++) {
        if (groups[i].options[j].value === value) {
          return groups[i].options[j];
        }
      }
    }

    return null;
  }, [groups, value]);

  const renderedGroups = useMemo(() => {
    return groups.map((group, key) => {
      const renderedOptions = group.options.map((option) => {
        return (
          <DropdownOption
            key={option.value}
            option={option}
            isSelected={option.value === value}
            onClick={(value) => {
              onChange(value);
              setVisible(false);
            }}
          />
        );
      });

      return (
        <DropdownGroup key={group.name || key} name={group.name}>
          {renderedOptions}
        </DropdownGroup>
      );
    });
  }, [groups, value]);

  return (
    <div className={styles.dropdown}>
      <div
        ref={rootRef}
        className={styles.selectedItem}
        onClick={() => {
          setVisible(true);
        }}
      >
        {!selectedOption && placeholder && (
          <Typography component="p" variant="info">
            {placeholder}
          </Typography>
        )}
        {selectedOption && <div>{selectedOption.name}</div>}
        <span className={styles.icon}>
          <MdArrowDropDown />
        </span>
        <Ink />
      </div>
      {isVisible && (
        <Portal>
          <div
            ref={targetRef}
            className={styles.groups}
            style={{
              width,
              height: popupHeight,
              transform: `translate(${x}px, ${y + scrollY + height + 4}px)`,
            }}
          >
            <Scrollbars>{renderedGroups}</Scrollbars>
          </div>
        </Portal>
      )}
    </div>
  );
});

export default Dropdown;
