import React, { FC, memo, useCallback } from 'react';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { tokenState } from '../../atoms/tokenState';
import { useRecoilState } from 'recoil';
import Button from '../../component-system/Button/Button';

interface Props {}

const Header: FC<Props> = memo(() => {
  const [token, setToken] = useRecoilState(tokenState);

  const handleLogout = useCallback(() => {
    setToken(null);
  }, [setToken]);

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div />
        <div className={styles.actions}>
          {token ? (
            <Button onClick={handleLogout}>로그아웃</Button>
          ) : (
            <Link to="/login">
              <Button>로그인</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
});

export default Header;
