import { atom, AtomEffect } from 'recoil';

const localStorageEffect: (key: string) => AtomEffect<string | null> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const token = localStorage.getItem(key);
    if (token !== null) {
      setSelf(token);
    }

    onSet((newValue) => {
      if (typeof newValue === 'string') {
        localStorage.setItem(key, newValue);
      } else if (newValue === null) {
        localStorage.removeItem(key);
      }
    });
  };

export const tokenState = atom<string | null>({
  key: 'tokenState',
  default: null,
  effects_UNSTABLE: [localStorageEffect('token')],
});
