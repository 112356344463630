import { useCallback } from 'react';

const keyMap: { [key: string]: boolean } = {};

window.addEventListener('keydown', (e) => {
  keyMap[e.key] = true;
});

window.addEventListener('keyup', (e) => {
  keyMap[e.key] = false;
});

export function useMutableKeyState() {
  return useCallback((key: string) => {
    return keyMap[key] || false;
  }, []);
}
