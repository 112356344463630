import { RecoilValue, useRecoilValueLoadable, } from 'recoil';
import { useEffect, useState } from 'react';

export function useKeepRecoilValue<T, P extends T>(recoilValue: RecoilValue<T>, defaultValue: P): [T, any, boolean] {
  const { contents, state } = useRecoilValueLoadable<T>(recoilValue);
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState(defaultValue);

  useEffect(() => {
    switch (state) {
      case 'hasError':
        setError(contents);
        break
      case 'hasValue':
        setValue(contents);
        break
    }
  }, [state, contents]);

  return [value, error, state === 'loading'];
}
