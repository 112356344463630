import React, { FC, memo, useLayoutEffect, useRef } from 'react';
import styles from './PopupTemplatePreview.module.scss';

interface Props {
  css: string;
  html: string;
}

const PopupTemplatePreview: FC<Props> = memo(({ css, html }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useLayoutEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentDocument) {
      return;
    }

    iframeRef.current.contentDocument.head.innerHTML = `<style>* { margin: 0; padding: 0; text-decoration: none; }</style><style>${css}</style>`;
    iframeRef.current.contentDocument.body.innerHTML = html;
  }, [css, html]);

  return (
    <div className={styles.popupTemplatePreview}>
      <iframe title="템플릿 미리보기" ref={iframeRef} width={100 * 4.8571428571} height={100} />
    </div>
  );
});

export default PopupTemplatePreview;
