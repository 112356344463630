import React, { FC, memo } from 'react';
import styles from './LoginPage.module.scss';
import LoginForm from '../../components/LoginForm/LoginForm';
import Layout from '../../components/Layout/Layout';

interface Props {}

const LoginPage: FC<Props> = memo(() => {
  return (
    <Layout className={styles.loginPage}>
      <LoginForm />
    </Layout>
  );
});

export default LoginPage;
