import React, { FC, memo, useEffect, useMemo, useRef, } from 'react';
import styles from './VectorSlider.module.scss';
import Slider from '../Slider/Slider';
import produce from 'immer';

interface Props {
  labels?: string[];
  step?: number;
  ranges: Array<[number, number]>;
  values: number[];
  onChange: (value: number[]) => void;
}

const VectorSlider: FC<Props> = memo(({ labels, step, ranges, values, onChange }) => {
  const currentValuesRef = useRef<number[]>(values);

  const renderedSliders = useMemo(() => {
    return ranges.map((range, key) => {
      return (
        <Slider
          key={key}
          step={step}
          label={labels ? labels[key] : undefined}
          range={range}
          value={values[key]}
          onChange={(value: number) => {
            onChange(
              produce(currentValuesRef.current, (draft) => {
                draft[key] = value;
              })
            );
          }}
        />
      );
    });
  }, [labels, step, ranges, values, onChange]);

  useEffect(() => {
    currentValuesRef.current = values;
  }, [values]);

  return <div className={styles.vectorSlider}>{renderedSliders}</div>;
});

export default VectorSlider;
