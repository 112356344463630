import React, { FC, memo } from 'react';
import styles from './SceneDetailPage.module.scss';
import SceneCreateForm from '../../components/SceneCreateForm/SceneCreateForm';
import { useParams } from 'react-router-dom';
import SceneUpdateForm from '../../components/SceneUpdateForm/SceneUpdateForm';

interface Props {}

interface SceneDetailParams {
  hashId: string;
}

const SceneDetailPage: FC<Props> = memo(() => {
  const { hashId } = useParams<SceneDetailParams>();
  const isNew = hashId === 'new';

  return (
    <div className={styles.sceneDetailPage}>
      {isNew ? <SceneCreateForm /> : <SceneUpdateForm hashId={hashId} />}
    </div>
  );
});

export default SceneDetailPage;
