import React, {forwardRef, ReactNode} from 'react'
import classNames from "classnames";

interface Props {
  d: string
  className?: string
  children?: ReactNode
}

const Path = forwardRef<SVGPathElement, Props>(({d, className}, ref) => {
  return (
    <path
      className={classNames(className)}
      d={d}
      ref={ref}
    />
  )
});

export default Path