import {gql} from '@apollo/client';
import {categoryFragment} from './queries';

export const LOGIN = gql`
  mutation ($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        hashId
        username
        nickname
        createdAt
      }
    }
  }
`;

export const UPLOAD_SCENE = gql`
  mutation ($data: UploadSceneInput!) {
    uploadScene(data: $data) {
      hashId
      description
      isPublished
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPSERT_CATEGORY = gql`
  ${categoryFragment}
  mutation upsertCategory($data: UpsertCategoryInput!) {
    upsertCategory(data: $data) {
      ...CategoryBody
    }
  }
`;

export const UPDATE_SCENE = gql`
  mutation ($data: UpdateSceneInput!) {
    updateScene(data: $data) {
      hashId
      category {
        hashId
        name
      }
      assetGroups {
        hashId
        error
        status
        assets {
          hashId
          key
          createdAt
        }
        createdAt
      }
      thumbnail
      templateCss
      templateHtml
      externalLink
      shadowIntensity
      shadowSoftness
      exposure
      scale
      orientation
      arPlacement
      arScale
      fieldOfView
      minFieldOfView
      maxFieldOfView
      isPublished
      name
      brand
      price
      description
      createdAt
      updatedAt
      isAvailableTemplate
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($hashId: ID!) {
    deleteCategory(hashId: $hashId)
  }
`;

export const DELETE_SCENE = gql`
  mutation deleteScene($hashId: ID!) {
    deleteScene(hashId: $hashId)
  }
`;

export const CREATE_HIT = gql`
  mutation( $data : CreateHitInput!){
    createHit( data : $data ){
      createdAt
      hashId
      id
      updatedAt
    }
  }
`

export const BULK_UPDATE_CATEGORIES = gql`
  mutation ($data: BulkUpdateCategoriesInput!) {
    bulkUpdateCategories(data: $data) {
      hashId
    }
  }
`;

export const BULK_UPDATE_SCENE = gql`
  mutation ($data: BulkUpdateSceneInput!) {
    bulkUpdateScene(data: $data)
  }
`;

export const BULK_DELETE_SCENE = gql`
  mutation ($data: BulkDeleteSceneInput!) {
    bulkDeleteScene(data: $data)
  }
`;

export const UPDATE_USER_INFORMATION = gql`
  mutation ($data: UpdateUserInformationInput!) {
    updateUserInformation(data: $data) {
      hashId
    }
  }
`;
