import { useEffect, useRef } from 'react';

export function useClickOutside(callback: () => void) {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [callback]);

  return [ref as any];
}
