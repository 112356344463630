import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './SceneUpdateForm.module.scss';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SCENE } from '../../cores/queries';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import TextField from '../../component-system/TextField/TextField';
import { useFormik } from 'formik';
import Switch from '../../component-system/Switch/Switch';
import SceneAssetGroups from '../SceneAssetGroups/SceneAssetGroups';
import Button from '../../component-system/Button/Button';
import { DELETE_SCENE, UPDATE_SCENE } from '../../cores/mutations';
import Dropdown from '../../component-system/Dropdown/Dropdown';
import { useCategoryGroups } from '../../hooks/useCategoryGroups';
import Slider from '../../component-system/Slider/Slider';
import VectorSlider from '../../component-system/VectorSlider/VectorSlider';
import {
  DEGREE_TO_RADIAN,
  MEDIA_HOST,
  RADIAN_TO_DEGREE,
  VIEWER_HOST,
} from '../../cores/constants';
import { nanoid } from 'nanoid';
import TextareaField from '../../component-system/TextareaField/TextareaField';
import QRCode from 'qrcode.react';
import PopupTemplatePreview from '../PopupTemplatePreview/PopupTemplatePreview';
import Typography from '../../component-system/Typography/Typography';
import Editor from '@monaco-editor/react';
import SceneCreateForm from '../SceneCreateForm/SceneCreateForm';
import Modal from '../../component-system/Modal/Modal';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useThumbnail } from '../../hooks/useThumbnail';

interface Props {
  hashId: string;
}

interface SceneUpdateFormValues {
  hashId: string;
  name: string;
  brand: string;
  price: string;
  thumbnail: string;
  category: string;
  templateCss: string;
  templateHtml: string;
  description: string;
  fieldOfView: string;
  minFieldOfView: string;
  maxFieldOfView: string;
  externalLink: string;
  exposure: number;
  shadowIntensity: number;
  shadowSoftness: number;
  orientation: [number, number, number];
  scale: [number, number, number];
  arPlacement: string;
  arScale: string;
  isPublished: boolean;
  isAvailableTemplate: boolean;
}

const SceneUpdateForm: FC<Props> = memo(({ hashId }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const history = useHistory();

  const [groups] = useCategoryGroups();
  const [iframeKey, setIframeKey] = useState(nanoid(8));
  const [isVisibleOverwriteFormPopup, setVisibleOverwriteFormPopup] =
    useState(false);
  const [isVisibleConfirmDeletePopup, setVisibleConfirmDeletePopup] =
    useState(false);

  const { data: getSceneData, refetch } = useQuery(GET_SCENE, {
    variables: {
      hashId,
    },
  });

  const [deleteScene] = useMutation(DELETE_SCENE, {
    variables: {
      hashId,
    },
  });

  useEffect(() => {
    if (
      !iframeRef.current ||
      !iframeRef.current.contentDocument ||
      !iframeRef.current.contentWindow
    ) {
      return;
    }
  }, []);

  const toDataURL = useCallback(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) {
      return;
    }

    iframeRef.current.contentWindow.postMessage({ type: 'toDataURL' }, '*');
  }, []);

  const [updateScene, { loading }] = useMutation(UPDATE_SCENE, {
    errorPolicy: 'all',
  });

  const onSubmit = useCallback(
    (values: SceneUpdateFormValues) => {
      const orientation = values.orientation.map((v) => v * DEGREE_TO_RADIAN);

      updateScene({ variables: { data: { ...values, orientation } } }).then(
        () => {
          refetch({ hashId });
        }
      );
    },
    [updateScene, hashId]
  );

  const { values, handleSubmit, setFieldValue, handleChange } =
    useFormik<SceneUpdateFormValues>({
      onSubmit,
      initialValues: {
        hashId,
        name: '',
        brand: '',
        price: '',
        thumbnail: '',
        description: '',
        category: '',
        externalLink: '',
        templateCss: '',
        templateHtml: '',
        fieldOfView: 'auto',
        minFieldOfView: 'auto',
        maxFieldOfView: 'auto',
        exposure: 1,
        shadowIntensity: 1,
        shadowSoftness: 1,
        orientation: [0, 0, 0],
        scale: [1, 1, 1],
        arPlacement: 'FLOOR',
        arScale: 'AUTO',
        isPublished: false,
        isAvailableTemplate: false,
      },
    });

  const iframeSrc = useMemo(() => {
    if (!VIEWER_HOST.endsWith('/')) {
      return `${VIEWER_HOST}/${hashId}`;
    }

    return VIEWER_HOST + hashId;
  }, [hashId]);

  const thumbnailSrc = useThumbnail(getSceneData?.getScene?.thumbnail);

  useEffect(() => {
    const handleMessage = ({ data: { type, data } }: MessageEvent) => {
      switch (type) {
        case 'toDataURL':
          setFieldValue('thumbnail', data);
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setFieldValue]);

  useEffect(() => {
    if (!getSceneData) {
      return;
    }

    setIframeKey(nanoid(8));
    setFieldValue('name', getSceneData.getScene.name || '');
    setFieldValue(
      'category',
      (getSceneData.getScene.category &&
        getSceneData.getScene.category.hashId) ||
        ''
    );
    setFieldValue('fieldOfView', getSceneData.getScene.fieldOfView || '');
    setFieldValue('minFieldOfView', getSceneData.getScene.minFieldOfView || '');
    setFieldValue('maxFieldOfView', getSceneData.getScene.maxFieldOfView || '');
    setFieldValue('brand', getSceneData.getScene.brand || '');
    setFieldValue('brand', getSceneData.getScene.brand || '');
    setFieldValue('price', getSceneData.getScene.price || '');
    setFieldValue('exposure', getSceneData.getScene.exposure);
    setFieldValue('shadowIntensity', getSceneData.getScene.shadowIntensity);
    setFieldValue('shadowSoftness', getSceneData.getScene.shadowSoftness);
    setFieldValue(
      'orientation',
      getSceneData.getScene.orientation.map(
        (v: number) => v * RADIAN_TO_DEGREE
      ) || [0, 0, 0]
    );
    setFieldValue('scale', getSceneData.getScene.scale || [1, 1, 1]);
    setFieldValue('arPlacement', getSceneData.getScene.arPlacement || 'FLOOR');
    setFieldValue('arScale', getSceneData.getScene.arScale || 'AUTO');
    setFieldValue('description', getSceneData.getScene.description || '');
    setFieldValue('templateCss', getSceneData.getScene.templateCss || '');
    setFieldValue('templateHtml', getSceneData.getScene.templateHtml || '');
    setFieldValue('externalLink', getSceneData.getScene.externalLink || '');
    setFieldValue('isPublished', getSceneData.getScene.isPublished || true);
    setFieldValue(
      'isAvailableTemplate',
      getSceneData.getScene.isAvailableTemplate || false
    );
  }, [getSceneData, setFieldValue]);

  return (
    <div className={styles.sceneUpdateForm}>
      <form onSubmit={handleSubmit}>
        <FormColumn label="제품명">
          <TextField name="name" value={values.name} onChange={handleChange} />
        </FormColumn>
        <FormColumn label="브랜드">
          <TextField
            name="brand"
            value={values.brand}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn label="가격">
          <TextField
            name="price"
            value={values.price}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn label="설명">
          <TextareaField
            name="description"
            value={values.description}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn label="카테고리">
          <Dropdown
            value={values.category}
            onChange={(value) => {
              setFieldValue('category', value);
            }}
            placeholder="카테고리를 선택해주세요."
            groups={groups}
          />
        </FormColumn>
        <FormColumn label="파일">
          {getSceneData && (
            <SceneAssetGroups assetGroups={getSceneData.getScene.assetGroups} />
          )}
        </FormColumn>
        <FormColumn label="썸네일">
          <div className={styles.thumbnail}>
            {(thumbnailSrc || values.thumbnail) && (
              <img alt="썸네일" width={400} src={values.thumbnail ? values.thumbnail : thumbnailSrc} />
            )}
            <Button type="button" onClick={toDataURL}>
              📷 촬영하기
            </Button>
          </div>
        </FormColumn>
        <FormColumn label="미리보기">
          <div className={styles.preview}>
            <TextField
              readonly
              value={iframeSrc}
              onClick={(e) => {
                e.currentTarget.select();
              }}
            />
            <div className={styles.qrcode}>
              <Typography component="p" className={styles.title}>
                QR 코드
              </Typography>
              <QRCode value={iframeSrc} fgColor="#24252b" bgColor="#fff" />
            </div>
          </div>
          <iframe
            ref={iframeRef}
            key={iframeKey}
            className={styles.viewer}
            src={iframeSrc + '?is_available_create_hit=false'}
            width={825}
            height={600}
          />
          <div className={styles.subactions}>
            <Button
              onClick={() => {
                setVisibleOverwriteFormPopup(true);
              }}
            >
              🥳 다시 업로드하기
            </Button>
          </div>
        </FormColumn>
        <FormColumn label="방향" additional="(Orientation)">
          <div className={styles.slider}>
            <VectorSlider
              step={10}
              labels={['X', 'Y', 'Z']}
              ranges={[
                [0, 360],
                [0, 360],
                [0, 360],
              ]}
              values={values.orientation}
              onChange={(value: number[]) => {
                setFieldValue('orientation', value);
              }}
            />
          </div>
        </FormColumn>
        <FormColumn label="크기" additional="(Scale)">
          <div className={styles.slider}>
            <VectorSlider
              labels={['X', 'Y', 'Z']}
              ranges={[
                [0, 1],
                [0, 1],
                [0, 1],
              ]}
              values={values.scale}
              onChange={(value: number[]) => {
                setFieldValue('scale', value);
              }}
            />
          </div>
        </FormColumn>
        <FormColumn label="밝기" additional="(Exposure)">
          <div className={styles.slider}>
            <Slider
              step={0.1}
              range={[0, 2]}
              value={values.exposure}
              onChange={(value) => {
                setFieldValue('exposure', value);
              }}
            />
          </div>
        </FormColumn>
        <FormColumn label="그림자 강도" additional="(Shadow Intensity)">
          <div className={styles.slider}>
            <Slider
              step={0.1}
              range={[0, 2]}
              value={values.shadowIntensity}
              onChange={(value) => {
                setFieldValue('shadowIntensity', value);
              }}
            />
          </div>
        </FormColumn>
        <FormColumn label="그림자 부드러움" additional="(Shadow Softness)">
          <div className={styles.slider}>
            <Slider
              step={0.1}
              range={[0, 2]}
              value={values.shadowSoftness}
              onChange={(value) => {
                setFieldValue('shadowSoftness', value);
              }}
            />
          </div>
        </FormColumn>
        <FormColumn label="시야" additional="(Field of view)">
          <TextField value={values.fieldOfView} name="fieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
        </FormColumn>
        <FormColumn label="최소 시야" additional="(Field of view)">
          <TextField value={values.minFieldOfView} name="minFieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
        </FormColumn>
        <FormColumn label="최대 시야" additional="(Field of view)">
          <TextField value={values.maxFieldOfView} name="maxFieldOfView" onChange={handleChange} placeholder="ex) auto, 30deg, 90deg" />
        </FormColumn>
        <FormColumn label="AR Placement">
          <Dropdown
            groups={[
              {
                options: [
                  { name: '바닥', value: 'FLOOR' },
                  { name: '벽', value: 'WALL' },
                ],
              },
            ]}
            value={values.arPlacement}
            onChange={(value) => {
              setFieldValue('arPlacement', value);
            }}
            placeholder="AR Placement"
          />
        </FormColumn>
        <FormColumn label="AR Scale">
          <Dropdown
            groups={[
              {
                options: [
                  { name: '자동', value: 'AUTO' },
                  { name: '고정', value: 'FIXED' },
                ],
              },
            ]}
            value={values.arScale}
            onChange={(value) => {
              setFieldValue('arScale', value);
            }}
            placeholder="AR Scale"
          />
        </FormColumn>
        <FormColumn label="팝업 활성화">
          <Switch
            value={values.isAvailableTemplate}
            onChange={(value) => {
              setFieldValue('isAvailableTemplate', value);
            }}
          />
        </FormColumn>
        {values.isAvailableTemplate && (
          <React.Fragment>
            <FormColumn label="팝업 템플릿 미리보기">
              <PopupTemplatePreview
                css={values.templateCss}
                html={values.templateHtml}
              />
            </FormColumn>
            <FormColumn label="팝업 템플릿 CSS">
              <div className={styles.monacoEditor}>
                <Editor
                  theme="vs-dark"
                  height={300}
                  value={values.templateCss}
                  options={{
                    fontFamily: 'Rec Mono Linear, Monaco, Consolas, Verdana',
                  }}
                  onChange={(value) => {
                    setFieldValue('templateCss', value || '');
                  }}
                  language="css"
                />
              </div>
            </FormColumn>
            <FormColumn label="팝업 템플릿 HTML">
              <div className={styles.monacoEditor}>
                <Editor
                  theme="vs-dark"
                  height={300}
                  value={values.templateHtml}
                  options={{
                    fontFamily: 'Rec Mono Linear, Monaco, Consolas, Verdana',
                  }}
                  onChange={(value) => {
                    setFieldValue('templateHtml', value || '');
                  }}
                  language="html"
                />
              </div>
            </FormColumn>
          </React.Fragment>
        )}
        <FormColumn label="외부 링크">
          <TextField
            name="externalLink"
            value={values.externalLink}
            onChange={handleChange}
            placeholder="https://"
          />
        </FormColumn>
        <FormColumn label="게시여부">
          <Switch
            value={values.isPublished}
            onChange={(value) => {
              setFieldValue('isPublished', value);
            }}
          />
        </FormColumn>
        <div className={styles.actions}>
          <Button
            color="danger"
            type="button"
            variant="rounded"
            size="large"
            onClick={() => {
              setVisibleConfirmDeletePopup(true);
            }}
          >
            삭제하기
          </Button>
          <Button
            loading={loading}
            type="submit"
            variant="rounded"
            size="large"
          >
            저장하기
          </Button>
        </div>
      </form>
      <Modal
        visible={isVisibleOverwriteFormPopup}
        onClose={() => {
          setVisibleOverwriteFormPopup(false);
        }}
      >
        <div className={styles.modal}>
          <SceneCreateForm
            hashId={hashId}
            onComplete={() => {
              setVisibleOverwriteFormPopup(false);
              refetch({ hashId });
            }}
          />
        </div>
      </Modal>
      <Modal
        visible={isVisibleConfirmDeletePopup}
        onClose={() => {
          setVisibleConfirmDeletePopup(false);
        }}
      >
        <div className={classNames(styles.modal, styles.confirmDeletePopup)}>
          <Typography component="h2">
            정말 삭제하시겠습니까?
          </Typography>
          <div className={styles.actions}>
            <Button color="danger" onClick={() => {
              deleteScene().then(() => {
                history.push('/scenes');
              });
            }}>
              네, 삭제합니다.
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default SceneUpdateForm;
