import React, { FC, memo } from 'react';
import styles from './Switch.module.scss';
import classNames from 'classnames';
import Typography from '../Typography/Typography';

interface Props {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const Switch: FC<Props> = memo(({ value, label, onChange }) => {
  return (
    <div
      className={classNames(styles.switch, {
        [styles.isActivated]: value,
      })}
    >
      {label && (
        <Typography component="p" className={styles.label}>
          {label}
        </Typography>
      )}
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          onChange(!value);
        }}
      />
    </div>
  );
});

export default Switch;
