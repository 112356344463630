import { selectorFamily } from 'recoil';
import { Category } from '../../cores/schema';
import { GET_CATEGORIES } from '../../cores/queries';
import { client } from '../../cores/client';
import { triggerState } from './trigger';

export type CategoryState = Category[];

export const getCategory = selectorFamily<CategoryState, any>({
  key: 'getCategory',
  get: () => async ({ get }) => {
    get(triggerState('getCategory'));
    try {
      const {
        data: { getCategories },
      } = await client.query<{ getCategories: Category[] }>({
        query: GET_CATEGORIES,
        fetchPolicy: 'no-cache'
      });

      return getCategories;
    } catch (e) {
      return [];
    }
  },
});
