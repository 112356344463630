import React, { FC, memo } from 'react';
import styles from './Typography.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  component: 'h1' | 'h2' | 'h3' | 'p';
  variant?: 'normal' | 'info' | 'error';
  align?: 'left' | 'center' | 'right'
}

const Typography: FC<Props & React.HTMLAttributes<HTMLElement>> = memo(
  ({ className, component, children, variant = 'normal', align = 'left', ...otherProps }) => {
    return React.createElement(component, {
      className: classNames(
        styles.typography,
        styles[component],
        styles[variant],
        styles[align],
        className
      ),
      children,
      ...otherProps,
    });
  }
);

export default Typography;
