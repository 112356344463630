import React, { ButtonHTMLAttributes, FC, memo } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Ink from 'react-ink';
import Spinner from '../Spinner/Spinner';

interface Props {
  transparent?: boolean;
  loading?: boolean;
  className?: string;
  type?: string;
  color?: 'primary' | 'danger';
  size?: 'small' | 'medium' | 'large';
  variant?: 'square' | 'rounded';
}

const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = memo(
  ({
     transparent,
    className,
    children,
    color = 'primary',
    size = 'small',
    variant = 'square',
    loading,
    ...otherProps
  }) => {
    return (
      <button
        className={classNames(
          styles.button,
          styles[size],
          styles[color],
          styles[variant],
          className,
          {
            [styles.isLoading]: loading,
            [styles.transparent]: transparent,
          }
        )}
        {...otherProps}
      >
        {loading && <Spinner variant="white" />}
        {children}
        <Ink />
      </button>
    );
  }
);

export default Button;
