import React, { FC, memo, useMemo } from 'react';
import styles from './SceneAssetGroups.module.scss';
import { AssetGroup } from '../../cores/schema';
import Spinner from '../../component-system/Spinner/Spinner';
import Typography from '../../component-system/Typography/Typography';

interface Props {
  assetGroups: AssetGroup[];
}

const SceneAssetGroups: FC<Props> = memo(({ assetGroups }) => {
  const renderedAssetGroups = useMemo(() => {
    return assetGroups.map((assetGroup, key) => {
      const inProgress = assetGroup.status === 'IN_PROGRESS';
      const rejected = assetGroup.status === 'REJECTED';
      const approved = assetGroup.status === 'APPROVED';

      return (
        <div key={assetGroup.hashId} className={styles.assetGroup}>
          {inProgress && (
            <div className={styles.inProgress}>
              <span>
                <Spinner className={styles.spinner} variant="white" />
                <span>업로드된 파일을 변환중입니다.</span>
              </span>
            </div>
          )}

          {rejected && (
            <div className={styles.rejected}>
              <Typography component="h3" className={styles.title}>
                오류가 발생하였습니다.
              </Typography>
              <Typography component="p" variant="error">
                {assetGroup.error}
              </Typography>
            </div>
          )}

          {approved && (
            <div className={styles.approved}>
              <Typography component="h3" className={styles.title}>
                파일이 성공적으로 변환되었습니다.
              </Typography>
              <Typography component="p" variant="info">
                {assetGroup.assets.length}개
              </Typography>
            </div>
          )}
        </div>
      );
    });
  }, [assetGroups]);

  return <div className={styles.sceneAssetGroups}>{renderedAssetGroups}</div>;
});

export default SceneAssetGroups;
