import React, { FC, memo, useMemo, useState } from 'react';
import styles from './CategoryItemForm.module.scss';
import { Category } from '../../cores/schema';
import { MdDragHandle } from 'react-icons/md';
import Typography from '../../component-system/Typography/Typography';
import CategoriesForm, { Item } from '../CategoriesForm/CategoriesForm';
import { chain } from 'lodash';
import Button from '../../component-system/Button/Button';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import classNames from 'classnames';
import Modal from '../../component-system/Modal/Modal';
import { useMutation } from '@apollo/client';
import { DELETE_CATEGORY } from '../../cores/mutations';
import { useRecoilState } from 'recoil';
import { triggerState } from '../../atoms/selectors/trigger';
import CategoryUpsertModal from '../CategoryUpsertModal/CategoryUpsertModal';

interface Props {
  parent?: boolean;
  category: Category;
  onChange?: (items: Item[]) => void;
}

const CategoryItemForm: FC<Props> = memo(
  ({ category, onChange, parent, ...otherProps }) => {
    const [isCreateCategoryModal, setCreateCategoryModal] = useState(false);
    const [isUpdateCategoryModal, setUpdateCategoryModal] = useState(false);
    const [isConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [deleteCategory] = useMutation(DELETE_CATEGORY);
    const [, updateTriggerState] = useRecoilState(triggerState('getCategory'));

    const countDisplay = useMemo(() => {
      if (category.count === undefined) {
        return null;
      }

      if (parent) {
        return `(${chain(category.children)
          .map((category) => category.count)
          .sum()
          .value()})`;
      }

      return `(${category.count})`;
    }, [parent, category.count, category.children]);

    return (
      <div className={styles.categoryItemForm}>
        <div className={styles.inner}>
          <div className={styles.block}>
            <div className={styles.dragable} {...otherProps}>
              <MdDragHandle />
            </div>
            <Typography component="p">{category.name}</Typography>
            <Typography component="p" variant="info" className={styles.count}>
              {countDisplay}
            </Typography>
          </div>
          <div className={classNames(styles.block, styles.actions)}>
            <Button
              transparent
              variant="rounded"
              onClick={() => {
                if (category.children && category.children.length > 0) {
                  alert('하위 카테고리를 먼저 삭제해주세요.');
                  return;
                }

                setConfirmDeleteModal(true);
              }}
            >
              <MdDelete />
            </Button>
            <Button
              transparent
              variant="rounded"
              onClick={() => {
                setUpdateCategoryModal(true);
              }}
            >
              <MdEdit />
            </Button>
            {parent && (
              <Button
                transparent
                variant="rounded"
                onClick={() => {
                  setCreateCategoryModal(true);
                }}
              >
                <MdAdd />
              </Button>
            )}
          </div>
        </div>
        <div className={styles.subCategories}>
          {category.children && (
            <CategoriesForm
              categories={category.children}
              onChange={onChange}
            />
          )}
        </div>
        {parent && (
          <CategoryUpsertModal
            visible={isCreateCategoryModal}
            onClose={() => {
              setCreateCategoryModal(false);
            }}
            order={category.children.length + 1}
            parent={category}
          />
        )}
        <CategoryUpsertModal
          visible={isUpdateCategoryModal}
          onClose={() => {
            setUpdateCategoryModal(false);
          }}
          hashId={category.hashId}
          category={category}
        />
        <Modal
          visible={isConfirmDeleteModal}
          onClose={() => {
            setConfirmDeleteModal(false);
          }}
        >
          <div className={classNames(styles.modal, styles.confirmDeletePopup)}>
            <Typography component="h3" className={styles.title}>
              "{category.name}" 카테고리를 정말 삭제하시겠습니까?
            </Typography>
            <div className={styles.actions}>
              <Button
                color="danger"
                onClick={() => {
                  deleteCategory({ variables: { hashId: category.hashId } })
                    .then(() => {})
                    .finally(() => {
                      updateTriggerState(Date.now());
                    });
                }}
              >
                네, 삭제합니다.
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default CategoryItemForm;
