import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';

const timeoutLink = new ApolloLinkTimeout(120000);

const uploadLink = createUploadLink({
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
  uri: process.env.REACT_APP_GRAPHQL_HOST,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!graphQLErrors) {
    return;
  }

  if (graphQLErrors.find(
    ({ extensions }) =>
      extensions &&
      extensions.code === 'UNAUTHENTICATED' &&
      extensions.response &&
      extensions.response.statusCode === 401
  )) {
    localStorage.removeItem('token');
    window.location.href = '/login';
  }
});

export const client = new ApolloClient({
  link: from([authLink, errorLink, timeoutLink, uploadLink as any]),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});
