import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './SceneListSearchForm.module.scss';
import TextField from '../../component-system/TextField/TextField';
import { useFormik } from 'formik';
import { useURLSearchParams } from '../../hooks/useURLSearchParams';
import { useHistory } from 'react-router-dom';
import Dropdown from '../../component-system/Dropdown/Dropdown';
import { useCategoryGroups } from '../../hooks/useCategoryGroups';
import Typography from '../../component-system/Typography/Typography';
import FormColumn from '../../component-system/FormColumn/FormColumn';
import Button from '../../component-system/Button/Button';

interface Props {}

const SceneListSearchForm: FC<Props> = memo(() => {
  const query = useURLSearchParams();
  const history = useHistory();
  const [groups] = useCategoryGroups();

  const onSubmit = useCallback((values) => {
    const newSearchParams = new URLSearchParams();

    for (const key in values) {
      if (values[key]) {
        newSearchParams.set(key, values[key]);
      } else {
        newSearchParams.delete(key);
      }
    }

    history.push(`?${newSearchParams.toString()}`);
  }, []);

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      search: '',
      category: '',
    },
    onSubmit,
  });

  useEffect(() => {
    const search = query.get('search') || '';
    const category = query.get('category') || '';

    setFieldValue('search', search);
    setFieldValue('category', category);
  }, [query, setFieldValue]);

  const handleClear = useCallback(() => {
    history.push('?');
  }, [history]);

  return (
    <div className={styles.sceneListSearchForm}>
      <Typography component="h2" className={styles.header}>
        프로덕트 목록
      </Typography>
      <form onSubmit={handleSubmit} className={styles.form}>
        <FormColumn label="제품명">
          <TextField
            name="search"
            placeholder="검색"
            value={values.search}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn label="카테고리">
          <Dropdown
            value={values.category}
            onChange={(value) => {
              setFieldValue('category', value);
              handleSubmit();
            }}
            placeholder="카테고리를 선택해주세요."
            groups={groups}
          />
        </FormColumn>
        <FormColumn>
          <div className={styles.actions}>
            <Button type="button" onClick={handleClear}>초기화</Button>
          </div>
        </FormColumn>
      </form>
    </div>
  );
});

export default SceneListSearchForm;
