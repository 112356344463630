import React, { FC, memo, useCallback, useEffect } from 'react';
import styles from './LoginForm.module.scss';
import { FormikHelpers, useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { LOGIN } from '../../cores/mutations';
import { tokenState } from '../../atoms/tokenState';
import TextField from '../../component-system/TextField/TextField';
import Box from '../../component-system/Box/Box';

import Button from '../../component-system/Button/Button';
import Typography from '../../component-system/Typography/Typography';
import { MdError } from 'react-icons/md';

interface Props {}

interface LoginFormPayload {
  username: string;
  password: string;
}

const LoginForm: FC<Props> = memo(() => {
  const setToken = useSetRecoilState(tokenState);
  const history = useHistory();
  const [login, { data, error }] = useMutation(LOGIN, {
    errorPolicy: 'all',
  });

  const onSubmit = useCallback(
    (
      { username, password },
      { setFieldError }: FormikHelpers<LoginFormPayload>
    ) => {
      if (!username.trim()) {
        setFieldError('username', '아이디를 입력해주세요.');
        return;
      }

      if (!password.trim()) {
        setFieldError('password', '비밀번호를 입력해주세요.');
        return;
      }

      login({ variables: { username, password } });
    },
    [login]
  );

  const { values, errors, handleSubmit, handleChange } =
    useFormik<LoginFormPayload>({
      onSubmit,
      initialValues: {
        username: '',
        password: '',
      },
    });

  useEffect(() => {
    if (!data) {
      return;
    }

    setToken(data.login.token);
    history.push('/dashboard');
  }, [data, history]);

  return (
    <Box className={styles.loginForm}>
      <Typography component="h1" className={styles.title}>
        로그인
      </Typography>
      <form className={styles.form} onSubmit={handleSubmit}>
        <TextField
          label="아이디"
          placeholder="아이디를 입력해주세요."
          name="username"
          value={values.username}
          onChange={handleChange}
          validationText={errors.username}
        />
        <TextField
          label="비밀번호"
          placeholder="비밀번호를 입력해주세요."
          name="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          validationText={errors.password}
        />
        <div className={styles.actions}>
          <Button className={styles.loginButton} size="medium" type="submit">
            로그인
          </Button>
        </div>
        {error?.message && (
          <Typography className={styles.errorMessage} component="p">
            <MdError />
            {error.message}
          </Typography>
        )}
      </form>
    </Box>
  );
});

export default LoginForm;
