import React, {FC, memo, useEffect, useMemo, useRef, useState} from 'react'
import styles from "./dashBoard.module.scss"
import {useCalculateDate} from "../../hooks/useCalculateDate";
import DashboardSvg from "../DashboardSvg/DashboardSvg";

interface IDashBoard {
  getDashboard: {
    __typename: string
    hits: string[]
  }
}

interface Props<T> {
  frequency: string
  data: T
}

const DashBoard: FC<Props<IDashBoard>> = memo(({frequency, data}) => {
  const [dateTotalCount, , before] = useCalculateDate(frequency)
  const [dashBoard, setDashBoard] = useState<{ [index: string]: number }>({})
  const parentBox = useRef<HTMLDivElement>(null)

  const dateHashTable = useMemo(() => {
    const table: { [index: string]: number } = {}

    let currentTime = before.getTime();

    for (let i = 0; i < dateTotalCount; i++) {
      const dateForHashtable = new Date(before.setTime(getInterval(i)))
      const year = dateForHashtable.getFullYear();
      const month = handleMonth(dateForHashtable.getMonth())
      const date = handleDate(dateForHashtable.getDate())

      table[`${year}-${month}-${date}`] = 0
    }

    function getInterval(idx: number) {
      return currentTime + (idx) * (60 * 60 * 1000 * 24);
    }

    function handleMonth(month: number) {
      return month < 9 ? `0${month + 1}` : month + 1
    }

    function handleDate(date: number) {
      return date < 10 ? `0${date}` : date
    }

    return table
  }, [before])

  useEffect(() => {
    if (!data) {
      return
    }

    data.getDashboard.hits.forEach((e: string) => {
      const format = e.split("T")[0];

      dateHashTable[format] += 1
    })
    setDashBoard(dateHashTable)
  }, [data])

  if (!data) {
    return null
  }
  return (
    <div className={styles.dashBoard} ref={parentBox}>
      <DashboardSvg
        parentBox={parentBox}
        data={dashBoard}
        frequency={frequency}
      />
    </div>
  )
})

export default DashBoard;
